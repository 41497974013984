import React from "react";
import { IMappingGroup, IMappingGroupWithMappings } from "../../../../types/mappingGroup.schema";
import { useMappings } from "../../../../state/mappings/useMappings";
import { Avatar, Badge, Button, Card, CardFooter, CardHeader, Divider, Switch, Table, TableBody, TableCell, TableCellActions, TableCellLayout, TableHeader, TableHeaderCell, TableRow, Text } from "@fluentui/react-components";
import { EyeOffRegular, EyeRegular, RewardRegular } from "@fluentui/react-icons";
import UpdateMappingGroupForm from "./UpdateMappingGroupForm";
import DeleteMappingGroupButton from "./DeleteMappingGroupButton";
import Flex from "../../container/Flex";
import UpdateMappingForm from "../UpdateMappingsForm";
import "./MappingGroup.css";
import useResponsive, { Breakpoint } from "../../../../hooks/useResponsive";
import MappingList from "../MappingList";
import MappingListDialog from "../MappingListDialog";
import EntityTypeSelect from "../../entityTypes/EntityTypeSelect";
import ExtendsGroupBadge from "./ExtendsGroupBadge";

export interface IMappingGroupProps {
    mappingGroup: IMappingGroupWithMappings
}

export default function MappingGroup(props: IMappingGroupProps) {

    const [mappingsVisible, setMappingsVisible] = React.useState<boolean>(false);

    const {
        isNarrower
    } = useResponsive(Breakpoint.Mobile);

    const {
        mappingGroup
    } = props;

    const name = mappingGroup.name ?? "Kein Name";

    return (
        <Card className="w-100">
            <Flex row>
                <Avatar className="mapping-group-avatar" size={64} shape="square" name={name} color="colorful" />
                <Flex row wrap justify="between" fullWidth align="start">
                    <Flex>
                        <Flex row>
                            <Text weight="bold">{name}</Text>
                            { mappingGroup.isGlobalPreset && <Badge color="danger">Vorlage</Badge> }
                        </Flex>
                        <ExtendsGroupBadge extendsGroup={mappingGroup.extendsGroup} />
                        <Text size={200}>Kontenrahmen</Text>
                        {
                            (mappingGroup.appliesTo && !!mappingGroup.appliesTo.length) && (
                                <EntityTypeSelect 
                                    selectedEntityTypes={mappingGroup.appliesTo}
                                    readOnly
                                />
                            )
                        }
                    </Flex>
                    <Switch
                        checked={mappingsVisible}
                        onChange={(_, data) => setMappingsVisible(data.checked)}
                        label="Konten anzeigen"
                    />
                </Flex>
            </Flex>
            {
                mappingsVisible 
                ? (
                    <Card className="w-100">
                        <Flex fullWidth>
                            <MappingList    
                                mappingGroup={mappingGroup} 
                                isTemplateUsage
                                slot={<UpdateMappingForm mappingGroup={mappingGroup} />}
                                slotPosition="left"
                            />
                        </Flex>
                    </Card>
                )
                : (
                    <CardFooter className="w-100">
                        <Flex row wrap fullWidth justify="between">
                            <UpdateMappingForm mappingGroup={mappingGroup} className="mapping-group-responsive-button" />
                            {
                                isNarrower && <Divider />
                            }
                            <Flex row wrap className="mapping-group-responsive-container" justify="end">
                                <UpdateMappingGroupForm mappingGroup={mappingGroup} isChildCreation className="mapping-group-responsive-button" />
                                <UpdateMappingGroupForm mappingGroup={mappingGroup} isDuplication className="mapping-group-responsive-button"/>
                                <UpdateMappingGroupForm mappingGroup={mappingGroup} className="mapping-group-responsive-button" />
                                <DeleteMappingGroupButton mappingGroup={mappingGroup} className="mapping-group-responsive-button" />
                            </Flex>
                        </Flex>
                    </CardFooter>

                )
            }
        </Card>
    )
}
import React from "react";
import { useEntityTypes } from "../../../state/entities/useEntityTypes";
import Flex from "../container/Flex";
import { Spinner, Table, TableBody, TableCell, TableHeader, TableRow } from "@fluentui/react-components";
import UpdateEntityTypeForm from "./UpdateEntityTypeForm";
import DeleteEntityTypeButton from "./DeleteEntityTypeButton";

export default function EntityTypeList() {
    const {
        entityTypes,
        loadingEntityTypes
    } = useEntityTypes();

    if (loadingEntityTypes) return <Spinner />
    
    return (
        <Table>
            <TableHeader>
                <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Aktionen</TableCell>
                </TableRow>
            </TableHeader>
            <TableBody>
                {
                    entityTypes && entityTypes.length
                    ? entityTypes.map(e => (
                        <TableRow key={e._id}>
                            <TableCell>{e.name}</TableCell>
                            <TableCell>
                                <Flex row>
                                    <UpdateEntityTypeForm entityType={e} />
                                    <DeleteEntityTypeButton entityType={e} />
                                </Flex>
                            </TableCell>    
                        </TableRow>
                    ))
                    : (
                        <TableRow>
                            <TableCell>Keine Einträge</TableCell>
                        </TableRow>
                    )
                }
            </TableBody>
        </Table>
    )
}
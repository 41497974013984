import React from "react";
import Flex from "../container/Flex";
import { Badge, Spinner, Text } from "@fluentui/react-components";
import { ArrowRightRegular, ChevronRightRegular } from "@fluentui/react-icons";
import { IMapping } from "../../../types/mapping.schema";
import CalculationElementPreview from "./calculations/CalculationElementPreview";

export default function MappingPreviewForGroup({ mapping }: { mapping: IMapping}) {

    if (!mapping) return null;
    if (mapping.isTemplate && !mapping.isCalculated) return <Text>-</Text>;

    return (
        <Flex row wrap>
            {
                mapping.isCalculated 
                ? (
                    !!mapping.calculationSteps && !!mapping.calculationSteps.length
                    ? mapping.calculationSteps.map((step, index) => (
                        <CalculationElementPreview key={index} element={step} index={index} />
                    ))
                    : <Text>Keine Berechnungsschritte</Text>
                )
                : (
                    mapping.accounts && mapping.accounts.length
                    ? mapping.accounts.map((a, index) => (
                        <Badge key={index}>
                            <Flex row>
                                <Text>{a.accountFrom}</Text>
                                {
                                    a.accountTo && (
                                        <>
                                            <ChevronRightRegular />
                                            <Text>{a.accountTo}</Text>
                                        </>
                                    )
                                }
                            </Flex>
                        </Badge>
                    ))
                    : <Text>Keine Konten zugewiesen</Text>

                )
            }
        </Flex>
    )
}
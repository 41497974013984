import { all } from "axios";
import { MappedObject } from "../types/collection.types";

export enum ApiRoute {
  Customer = "customers",
  User = "users",
  MappingGroup = "mapping-groups",
  Mappings = "mappings",
  Session = "sessions",
  Charts = "charts",
  SlideDeck = "slide-decks",
  EntityTypes = "entity-types"
}

export enum RouteParam {
  Customer = ":customer",
  User = ":user",
  MappingGroup = ":mappingGroup",
  Mapping = ":mapping",
  Chart = ":chart",
  SlideDeck = ":slideDeck",
  EntityType = ":entityType"
}

export interface IRouteParam {
  param: RouteParam,
  value: string
}

export type RouteParams = MappedObject<RouteParam, string>

export type RouteParamValues<T> = { [key in keyof T]?: RouteParam };

export interface IDynamicRoute {
  route: string,
  getRoute: (values: RouteParams) => string | null,
  getValue: (values: IRouteParam[]) => string | null
}

const getRoute = (route: ApiRoute, ...parts: string[]): IDynamicRoute => {

  const getValue = () => {
    if (!route) return "/";
    if (!parts || !parts.length) return `/${route}`;
    return `/${route}/${parts.join("/")}`;
  }

  const routeValue = getValue();

  return {
    route: routeValue,
    getRoute: (values: RouteParams) => {
      if (!values) return routeValue;

      let r = routeValue;

      for (const [key, value] of Object.entries(values)) {
        r = r.replace(key, value);
      }

      return r;
    },
    getValue: (values: IRouteParam[]) => {
      if (!values || !values.length) return routeValue;
      return values.reduce((prev, curr) => prev.replace(curr.param, curr.value), routeValue);
    }
  }
}

export const Routes = {
  users: {
    all: getRoute(ApiRoute.User),
    byId: getRoute(ApiRoute.User, RouteParam.User),
  },
  customers: {
    current: getRoute(ApiRoute.Customer, "current"),
    all: getRoute(ApiRoute.Customer),
    byId: getRoute(ApiRoute.Customer, RouteParam.Customer),
    settings: getRoute(ApiRoute.Customer, RouteParam.Customer, "settings"),
    users: getRoute(ApiRoute.Customer, RouteParam.Customer, ApiRoute.User)
  },
  mappingGroups: {
    all: getRoute(ApiRoute.MappingGroup),
    byId: getRoute(ApiRoute.MappingGroup, RouteParam.MappingGroup),
    duplicate: getRoute(ApiRoute.MappingGroup, RouteParam.MappingGroup, "duplicate"),
    tree: getRoute(ApiRoute.MappingGroup, RouteParam.MappingGroup, "tree"),
    templates: getRoute(ApiRoute.MappingGroup, RouteParam.MappingGroup, "templates"),
    charts: getRoute(ApiRoute.MappingGroup, RouteParam.MappingGroup, ApiRoute.Charts)
  },
  mappings: {
    all: getRoute(ApiRoute.Mappings),
    templates: getRoute(ApiRoute.Mappings, "templates"),
    forGroup: getRoute(ApiRoute.MappingGroup, RouteParam.MappingGroup, ApiRoute.Mappings),
    byId: getRoute(ApiRoute.Mappings, RouteParam.Mapping)
  },
  session: {
    current: getRoute(ApiRoute.Session)
  },
  charts: {
    all: getRoute(ApiRoute.Charts),
    byId: getRoute(ApiRoute.Charts, RouteParam.Chart)
  },
  slideDecks: {
    all: getRoute(ApiRoute.SlideDeck),
    byId: getRoute(ApiRoute.SlideDeck, RouteParam.SlideDeck),
    downloadPreset: getRoute(ApiRoute.SlideDeck, RouteParam.SlideDeck, "download")
  },
  entityTypes: {
    all: getRoute(ApiRoute.EntityTypes),
    templates: getRoute(ApiRoute.EntityTypes, "templates"),
    byId: getRoute(ApiRoute.EntityTypes, RouteParam.EntityType)
  }
}


import React from "react";
import { useEntityTypes } from "../../../state/entities/useEntityTypes";
import { Badge, Spinner, Text } from "@fluentui/react-components";
import Flex from "../container/Flex";
import { BuildingRegular, CheckmarkRegular } from "@fluentui/react-icons";
import LocalStateSelect from "../select/LocalStateSelect";
import { StorageKey } from "../../../hooks/usePersistance";

export interface IEntityTypeSelectProps {
    selectedEntityTypes?: string[],
    availableIds?: string[],
    limitSelectionToAvailableIds?: boolean,
    label?: string,
    as?: "badge" | "select",
    description?: string,
    size?: "small" | "medium" | "large",
    onChange?: (entityTypes: string[]) => void,
    readOnly?: boolean
}

export default function EntityTypeSelect(props: IEntityTypeSelectProps) {

    const {
        onChange,
        label,
        description,
        limitSelectionToAvailableIds,
        size = "large",
        readOnly,
        availableIds,
        selectedEntityTypes = []
    } = props;

    const {
        entityTypes,
        entityTypesById,
        loadingEntityTypes
    } = useEntityTypes();


    const getAvailableItems = () => {
        if (!limitSelectionToAvailableIds) return entityTypes ?? [];
        if (!entityTypes || !entityTypes.length) return [];
        if (!availableIds || !availableIds.length) return entityTypes ?? [];
        return entityTypes.filter((t) => availableIds.includes(t._id));
    }

    const items = getAvailableItems();
    
    if (props.as === "select") return (
        <LocalStateSelect
            content={{
                byId: entityTypesById,
                items: items
            }}
            icon={<BuildingRegular />}
            itemsName="Firmentyp"
            labelKey="name"
            loading={loadingEntityTypes}
            storageKey={StorageKey.EntityType}
        />
    )
        
    if (loadingEntityTypes) return <Spinner />
    if (!items || !items.length) return null;
    if (!readOnly && !onChange) return null;
    if (readOnly && (!selectedEntityTypes || !selectedEntityTypes.length)) return null;
    
    const addToExisting = (id: string) => {
        if (!selectedEntityTypes || !selectedEntityTypes.length) return [id];
        return [...selectedEntityTypes, id];
    }

    const removeFromExisting = (id: string) => {
        if (!selectedEntityTypes || !selectedEntityTypes.length) return [];
        return selectedEntityTypes.filter((t) => t !== id);
    }

    const isSelected = (id: string) => !!(selectedEntityTypes?.includes(id));

    const select = (
        <Flex row gap={1}>
            {
                items.map((type) => {

                    const selected = isSelected(type._id);

                    if (readOnly && !selected) return null;

                    return (
                        <Badge 
                            className={readOnly ? undefined : "clickable"}
                            size={size}
                            icon={(selected && !readOnly) ? <CheckmarkRegular /> : undefined}
                            key={type._id} 
                            color={readOnly ? "informative" : (selected ? "brand" : "informative")} 
                            onClick={() => {
                                if (readOnly) return;
                                if (selected) onChange(removeFromExisting(type._id));
                                else onChange(addToExisting(type._id));
                            }}
                        >
                            {type.name}
                        </Badge>
                    )
                })
            }
        </Flex>
    );

    if (!label && !description) return select;

    return (
        <Flex fullWidth>
            {
                label && <Text>{label}</Text>
            }
            {
                description && <Text size={200}>{description}</Text>
            }
            {select}
        </Flex>
    )
}
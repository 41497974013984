import React from "react";
import { IChartItemProps } from "./ChartItem";
import CustomButton from "../button/CustomButton";
import useApi from "../../../api/useApi";
import { useCharts } from "../../../state/charts/useCharts";
import { DeleteRegular } from "@fluentui/react-icons";
import { useUser } from "../../../state/user/useUser";

export default function DeleteChartButton({chart}: IChartItemProps) {
    
    const {
        chartsDelete
    } = useApi();

    const {
        user     
    } = useUser();

    const {
        reloadCharts
    } = useCharts();

    const deleteChart = async () => {
        const res = await chartsDelete(chart._id);
        if (!res) return;
        await reloadCharts();
    }

    if (!user?.isGlobalAdmin && chart.isGlobal) return null;

    return (
        <CustomButton
            className="w-100"
            secondStepQuestion="Sicher?"
            onClick={deleteChart}
            icon={<DeleteRegular />}
            color="danger"
            text="Löschen"        
        />
    )
}
import { Button, Card, Text } from "@fluentui/react-components";
import { ChevronLeftRegular, ChevronRightRegular } from "@fluentui/react-icons";
import React from "react";
import { generateClassName } from "../../../hooks/useAttributes";
import useSlideUtil from "../../../hooks/useSlideUtil";
import { ICoordinates, ISlide, ISlidePadding } from "../../../types/slideDeck.schema";
import Flex from "../container/Flex";
import PaddedSlidePreview from "./PaddedSlidePreview";
import SlideChartPreview from "./SlideChartPreview";
import "./SlidePreview.css";

export default function SlidePreview(props: {
    onClick: (index: number) => void,
    swap: (a: number, b: number) => void,
    active?: boolean,
    padding: ISlidePadding,
    slideIndex?: number,
    arrayIndex?: number,
    totalItems?: number,
    slideSize: ICoordinates,
    showAddSlideButton?: boolean
    slide?: ISlide
}) {
    
    const {
        onClick,
        active,
        swap,
        totalItems,
        slideIndex,
        arrayIndex,
        padding,
        slideSize,
        showAddSlideButton,
        slide
    } = props;

    const {
        slideCss
    } = useSlideUtil(slideSize);

    return (
        <Flex>
            { 
                slide
                ? <Text>Folie {slide?.index >= 0 ? slide.index : slideIndex}</Text>
                : <Text>Neue Folie</Text>
            }
            <Card 
                className={generateClassName("slide-preview p-0", { value: active, onTrue: "slide-preview-active"})} 
                onClick={() => onClick(slideIndex)}
                style={slideCss}
            >
                <PaddedSlidePreview padding={padding} inactive={!active} slideSize={slideSize}>
                    {
                        slide.charts && !!slide.charts.length 
                        ? slide.charts.map((c, index) => <SlideChartPreview padding={padding} key={index} chart={c} index={index} slideSize={slideSize} />)
                        : <Flex fullWidth fullHeight justify="center" align="center"><Text>Keine Diagramme</Text></Flex>
                    }
                </PaddedSlidePreview>
            </Card>
            <Flex row justify="center" fullWidth gap={1}>
                <Flex row>
                    <Button icon={<ChevronLeftRegular />} onClick={() => swap(arrayIndex, arrayIndex - 1)} disabled={arrayIndex <= 0} />
                    <Button icon={<ChevronRightRegular />} onClick={() => swap(arrayIndex, arrayIndex + 1)} disabled={arrayIndex >= totalItems + 1} />
                </Flex>
            </Flex>
        </Flex>
    )
}
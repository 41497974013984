import React from "react";
import usePersistance, { StorageKey } from "../../../../hooks/usePersistance";
import { useMappingGroups } from "../../../../state/mappingGroups/useMappingGroups";
import EntityTypeSelect from "../../entityTypes/EntityTypeSelect";

export default function CurrentGroupEntityTypeSelect() {

    const {
        mappingGroupsById
    } = useMappingGroups();

    const {
        value
    } = usePersistance<string>(StorageKey.SelectedMappingGroup);

    if (!value) return null;
    if (!mappingGroupsById) return null;

    const group = mappingGroupsById[value];

    if (!group || !group.appliesTo || group.appliesTo.length <= 1) return null;

    return (
        <EntityTypeSelect 
            as="select"
            availableIds={group.appliesTo}
        />
    );
}
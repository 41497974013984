import { Divider, Tab, TabList, Text } from "@fluentui/react-components";
import { ColorRegular, Dismiss12Regular } from "@fluentui/react-icons";
import { useFormikContext } from "formik";
import React from "react";
import { useMappingGroups } from "../../../state/mappingGroups/useMappingGroups";
import { IChartDocument } from "../../../types/charts.schema";
import { TemplateFieldId } from "./ChartGroupField";

interface IChartGroupTabsProps {
    selectedGroup: string,
    onSelect: (group: string) => void,
    onRemove: (group: string) => void
}

export default function ChartGroupTabs(props: IChartGroupTabsProps) {
    
    const {
        onSelect,
        onRemove,
        selectedGroup    
    } = props;

    const {
        mappingGroups
    } = useMappingGroups();

    const {
        values
    } = useFormikContext<IChartDocument>();

    const groups = values.groups && !!values.groups.length 
    ? values.groups.map(g => typeof g.mappingGroup === "string" ? mappingGroups.find(m => m._id === g.mappingGroup) : g.mappingGroup) 
    : [];

    return (
        <TabList 
            selectedValue={selectedGroup} 
            onTabSelect={(_, data) => onSelect?.(data.value as string)}
            appearance="transparent"
            size="small"
        >
            <Tab
                color="#0078D4"
                value={TemplateFieldId}
                icon={<ColorRegular />}
                slot="before"
            >
                Vorlagen
            </Tab>
            {
                (!!groups && !!groups.length) && groups.map(g => {
                    if (!g) return null;
                    return (
                        <Tab 
                            key={g._id}
                            value={g._id}
                            slot="after" 
                            icon={<Dismiss12Regular onClick={() => onRemove(g._id)} />}
                        >
                            {g.name}
                        </Tab>
                    )
                })
            }
        </TabList>
    )
}
import React from "react";
import { useMappingTemplates } from "../../../../state/mappings/useMappingTemplates";
import { Spinner } from "@fluentui/react-components";
import UpdateMappingForm from "../UpdateMappingsForm";
import { IMappingGroupWithMappings } from "../../../../types/mappingGroup.schema";

export default function CreateTemplateMappingForm() {
    const {
        loadingMappingTemplates,
        mappingTemplates
    } = useMappingTemplates();

    return (
        <UpdateMappingForm 
            loading={loadingMappingTemplates}
            mappingGroup={{
                mappings: mappingTemplates || []
            } as IMappingGroupWithMappings}
            isTemplateEditing
        />
    )
}
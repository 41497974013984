import React from "react";
import Flex from "../container/Flex";
import { Avatar, Text } from "@fluentui/react-components";
import { ChevronLeftRegular, ChevronRightRegular, ColorRegular, LockClosedRegular, SlideGridRegular } from "@fluentui/react-icons";
import { generateClassName } from "../../../hooks/useAttributes";
import "./IntroductionCarousel.css";
import useResponsive, { Height } from "../../../hooks/useResponsive";

interface ISlide {
    title: string,
    description: string,
    image?: any,
    icon?: React.ReactElement
}

const Slides: Array<ISlide> = [
    {
        title: "Willkommen bei SBT!",
        description: "Erwecken Sie Ihre Buchhaltung mit Diagrammen zum Leben"
    },
    {
        title: "Einfache Anpassung und dynamische Präsentationen",
        description: "Ein Diagramm - viele Präsentationen, Kontenrahmen, Mandanten und mehr",
        icon: <ColorRegular />
    },
    {
        title: "Präsentationsvorlagen",
        description: "Stellen Sie sich eigene Präsentationsvorlagen zusammen und nutzen Sie diese organisationsweit",
        icon: <SlideGridRegular />
    },
    {
        title: "Sicher und einfach",
        description: "Alle Diagramme werden lokal auf Ihrem Rechner generiert",
        icon: <LockClosedRegular />
    }
];

export default function IntroductionCarousel() {
    
    const [slide, setSlide] = React.useState<number>(0);

    const {
        isLower
    } = useResponsive(undefined, Height.Small);

    const slideCount = Slides.length;

    const carouselSwitch = (back: boolean = false) => {
        
        const disabled = (back && slide <= 0) || (!back && slide >= slideCount - 1);

        return (
            <div className={disabled ? undefined : "clickable"}>
                <Avatar
                    size={isLower ? 24 : 32}
                    color={disabled ? undefined : "brand"}
                    icon={
                        back
                        ? <ChevronLeftRegular />
                        : <ChevronRightRegular />
                    }
                    onClick={() => {
                        if (disabled) return;
                        if (back) setSlide(slide - 1)
                        else setSlide(slide + 1)
                    }}
                />
            </div>
        );
    }

    const slideContent = (content: ISlide) => (
        <Flex fullWidth fullHeight className="introduction-carousel-slide-container">
            <Flex row justify="center" fullWidth>
                {carouselSwitch(true)}
                {Slides.map((_, index) => (
                    <div 
                        onClick={() => setSlide(index)}
                        key={index} 
                        className={generateClassName("clickable introduction-carousel-dot", {
                            value: slide === index,
                            onTrue: "introduction-carousel-dot-active"
                        })}
                    />
                ))}
                {carouselSwitch()}
            </Flex>
            <Flex fullWidth fullHeight align="center">
                <div className="introduction-carousel-icon-container">
                    <Avatar
                        size={isLower ? 24 : 64}
                        icon={content.icon}
                    />
                </div>
                <Text size={isLower ? 400 : 700} weight="bold" align="center">
                    {content.title}
                </Text>
                <Text align="center" size={isLower ? 300 : 400}>{content.description}</Text>
            </Flex>
        </Flex>
    );

    return (
        <Flex className="position-relative" fullWidth>
            {slideContent(Slides[slide])}
        </Flex>
    )
}
import axios, { CanceledError, HttpStatusCode } from "axios";
import useNotifications from "../hooks/useModal";
import useStorage, { StorageKey } from "../hooks/usePersistance";
import { ModalType } from "../state/modal/modal.state";
import { IChart, IChartDocument } from "../types/charts.schema";
import { ICustomer, ICustomerDocument, ICustomerSettings } from "../types/customer.schema";
import { IUploadableFile } from "../types/googlecloudstorage.types";
import { IMappingDocument } from "../types/mapping.schema";
import { IMappingGroupImport } from "../types/mappingGroup.schema";
import { ILogInRequest } from "../types/session.schema";
import { ISlideDeck, ISlideDeckDocument } from "../types/slideDeck.schema";
import { IUser, IUserDocument } from "../types/user.schema";
import { Config } from "../util/config";
import { IDynamicRoute, IRouteParam, RouteParam, RouteParams, RouteParamValues, Routes } from "./apiRoutes";
import { IEntityType, IEntityTypeBase } from "../types/entityType.schema";

type HttpMethod = "GET" | "POST" | "DELETE";

interface IDispatchParams<T = Object> {
  body?: T,
  bodyParams?: RouteParamValues<T>,
  params?: RouteParams,
  abortController?: AbortController | null,
  messageOnSuccess?: string,
  showMessage?: boolean,
  showMessageOnSuccess?: boolean,
  abortSignal?: AbortSignal | null
}

export interface IApiResponse<T = any> {
  success: boolean,
  canceled: boolean,
  message: string,
  data: T
}

export default function useApi() {

  const {
    value: token
  } = useStorage(StorageKey.Session);

  const {
    showNotification
  } = useNotifications();

  const isErrorApiResponse = (apiResponse: IApiResponse | null | undefined) => {
    if (!apiResponse) return true;
    return !apiResponse.success;
  }

  const createApiError = <T = any>(message: string = "Es ist ein Fehler aufgetreten."): IApiResponse<T> => ({
    success: false,
    canceled: false,
    message: message,
    data: null
  });

  const createCanceledResponse = <T = any>(): IApiResponse<T> => ({
    canceled: true,
    success: false,
    message: "Canceled.",
    data: null
  });

  function createApiSuccessPayload<T>(payload: T): IApiResponse<T> {
    return ({
      success: true,
      canceled: false,
      message: "Ok",
      data: payload
    });
  }

  const handleApiCall = async<T = any>(apiCall: () => Promise<IApiResponse<T>>, showMessage: boolean = true, showMessageOnSuccess: boolean = false, message: string = "Erfolg"): Promise<IApiResponse<T> | null> => {
    const result = await apiCall();

    if (result && result.canceled) return null;

    if (!result) {
      if (showMessage) showNotification({
        title: "Fehler",
        text: "Es ist ein Fehler aufgetreten.",
        type: ModalType.Error
      });

      return null;
    }

    if (isErrorApiResponse(result)) {
      if (showMessage) showNotification({
        title: "Fehler",
        text: result.message || "Es ist ein Fehler aufgetreten.",
        type: ModalType.Error
      });

      return null;
    }

    if (showMessage && showMessageOnSuccess) showNotification({
      title: "Erfolg",
      text: message,
      type: ModalType.Success
    });

    return result;
  }

  const apiRequest = async<ResponseT = any, BodyT = any>(method: HttpMethod = "GET", endpoint: string | IDynamicRoute, params: IDispatchParams<BodyT> = {}) => await handleApiCall<ResponseT>(async () => {
    try {
      if (!endpoint) return createApiError<ResponseT>("No endpoint specified.");

      const {
        body,
        abortController,
        abortSignal
      } = params;

      const getEndpoint = () => {
        if (typeof endpoint === "string") return endpoint;

        if (!!params.params) return endpoint.getRoute(params.params);
        if (!params.bodyParams) return endpoint.route;

        const result: Array<IRouteParam> = [];

        for (const [bodyKey, routeParam] of Object.entries(params.bodyParams)) {
          const value = body[bodyKey];
          if (!value) continue;
          result.push({ param: routeParam as RouteParam, value: value });
        }

        return endpoint.getValue(result);
      }

      const url = Config.ApiUrl;
      const usableEndpoint = getEndpoint();

      const res = await axios({
        baseURL: url,
        method: method,
        url: usableEndpoint,
        headers: {
          'Authorization': `Bearer ${token}`,
          'Access-Control-Allow-Origin': "*"
        },
        signal: abortController ? abortController.signal : abortSignal || undefined,
        data: body
      });

      if (!res) return createApiError<ResponseT>("Anfrage konnte nicht gesendet werden.");
      if (res.status >= HttpStatusCode.Ok && res.status < HttpStatusCode.MultipleChoices) return createApiSuccessPayload<ResponseT>(res.data);

      return createApiError<ResponseT>("Die Anfrage war fehlerhaft.");

    }
    catch (err: any) {
      if (err instanceof CanceledError) return createCanceledResponse<ResponseT>();
      if (err?.response?.data?.message) return createApiError<ResponseT>(err.response.data.message);
    }

    return createApiError<ResponseT>("Anfrage fehlgeschlagen.");
  }, params?.showMessage, params?.showMessageOnSuccess, params?.messageOnSuccess);

  return {
    swrFetch: async (endpoint: string, options?: RequestInit) => {
      try {
        const res = await apiRequest("GET", endpoint, { abortSignal: options?.signal, showMessage: false });
        if (!res.success) return null;

        return res.data || true;
      }
      catch {
        return null;
      }
    },

    sessionStart: async (body: ILogInRequest) => await apiRequest<{ token: string }, ILogInRequest>("POST", Routes.session.current, { body }),
    sessionEnd: async () => await apiRequest("DELETE", Routes.session.current),

    usersCreate: async (body: IUserDocument) => await apiRequest("POST", Routes.users.all, { body }),
    usersUpdate: async (id: string, body: IUser) => await apiRequest("POST", Routes.users.byId, { body, params: { [RouteParam.User]: id } }),
    usersDelete: async (id: string) => await apiRequest("DELETE", Routes.users.byId, { params: { [RouteParam.User]: id } }),

    customersCreate: async (body: ICustomerDocument) => await apiRequest("POST", Routes.customers.all, { body }),
    customersUpdate: async (id: string, body: ICustomer) => await apiRequest("POST", Routes.customers.byId, { body, params: { [RouteParam.Customer]: id } }),
    customersDelete: async (id: string) => await apiRequest("DELETE", Routes.customers.byId, { params: { [RouteParam.Customer]: id } }),

    customerSettingsUpdate: async (id: string, body: ICustomerSettings) => await apiRequest("POST", Routes.customers.settings, { body, params: { [RouteParam.Customer]: id } }),

    mappingGroupsCreate: async (body: IMappingGroupImport) => await apiRequest("POST", Routes.mappingGroups.all, { body }),
    mappingGroupsUpdate: async (id: string, body: IMappingGroupImport) => await apiRequest("POST", Routes.mappingGroups.byId, { body, params: { [RouteParam.MappingGroup]: id } }),
    mappingGroupsDuplicate: async (id: string, body: IMappingGroupImport) => await apiRequest("POST", Routes.mappingGroups.duplicate, { body, params: { [RouteParam.MappingGroup]: id } }),
    mappingGroupsDelete: async (id: string) => await apiRequest("DELETE", Routes.mappingGroups.byId, { params: { [RouteParam.MappingGroup]: id } }),

    mappingsCreate: async (mappingGroupId: string, body: IMappingDocument) => await apiRequest("POST", Routes.mappings.forGroup, { body, params: { [RouteParam.MappingGroup]: mappingGroupId } }),
    mappingsCreateTemplate: async (body: IMappingDocument) => await apiRequest("POST", Routes.mappings.templates, { body }),
    mappingsUpdate: async (mappingId: string, body: IMappingDocument) => await apiRequest("POST", Routes.mappings.byId, { body, params: { [RouteParam.Mapping]: mappingId } }),
    mappingsDelete: async (mappingId: string) => await apiRequest("DELETE", Routes.mappings.byId, { params: { [RouteParam.Mapping]: mappingId } }),

    chartsCreate: async (body: IChartDocument) => await apiRequest("POST", Routes.charts.all, { body }),
    chartsUpdate: async (id: string, body: IChart) => await apiRequest("POST", Routes.charts.byId, { body, params: { [RouteParam.Chart]: id } }),
    chartsDelete: async (id: string) => await apiRequest("DELETE", Routes.charts.byId, { params: { [RouteParam.Chart]: id } }),

    slideDecksCreate: async (body: ISlideDeckDocument) => await apiRequest("POST", Routes.slideDecks.all, { body }),
    slideDecksUpdate: async (body: ISlideDeck) => await apiRequest("POST", Routes.slideDecks.byId, { body, bodyParams: { "_id": RouteParam.SlideDeck } }),
    slideDecksDownload: async (id: string) => await apiRequest<IUploadableFile>("GET", Routes.slideDecks.downloadPreset, { params: { [RouteParam.SlideDeck]: id } }),
    slideDecksDelete: async (id: string) => await apiRequest("DELETE", Routes.slideDecks.byId, { params: { [RouteParam.SlideDeck]: id } }),

    entityTypesCreate: async (body: IEntityTypeBase) => await apiRequest<IEntityType>("POST", Routes.entityTypes.all, { body }),
    entityTypesUpdate: async (body: IEntityType) => await apiRequest<IEntityType>("POST", Routes.entityTypes.byId, { bodyParams: { "_id": RouteParam.EntityType }, body }),
    entityTypesDelete: async (body: IEntityType) => await apiRequest<IEntityType>("DELETE", Routes.entityTypes.byId, { bodyParams: { "_id": RouteParam.EntityType }, body }),
  }
}
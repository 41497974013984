import React from "react";
import useOfficeVersion from "../../../hooks/useOfficeVersion";
import { CheckmarkRegular, CopyRegular, InsertRegular } from "@fluentui/react-icons";
import { IChartDocument } from "../../../types/charts.schema";
import useNotifications from "../../../hooks/useModal";
import { ModalType } from "../../../state/modal/modal.state";
import useChartImage from "../../../hooks/useChartImage";
import CustomButton from "../button/CustomButton";
import useDynamicChartImage from "../../../hooks/useDynamicChartImage";

export interface IInsertChartButtonProps {
    chart: IChartDocument
}

export default function InsertChartButton(props: IInsertChartButtonProps) {

    const [clicked, setClicked] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(false);

    const {
        chart
    } = props;

    const {
        showNotification
    } = useNotifications();

    const {
        version
    } = useOfficeVersion();

    const {
        getImageFromChart,
        pasteChartImageToActiveSlide
    } = useDynamicChartImage();

    const copyImage = async (): Promise<boolean> => {
        const image = await getImageFromChart(chart);
        if (!image) return false;
        const blob = await fetch(image).then(res => res.blob());
        if (!blob) return false;
        navigator.clipboard.write([new ClipboardItem({'image/png': blob})]);
        showNotification({
            text: "Das Diagramm wurde in die Zwischenablage kopiert.",
            type: ModalType.Success
        });
        return true;
    }
    
    const clickHandler = async () => {
        setLoading(true);

        try {
            const res = (
                version.isWeb
                ? await copyImage()
                : await pasteChartImageToActiveSlide(chart)
            );

            if (!res) return;

            setClicked(true);
            setTimeout(() => {
                setClicked(false);
            }, 1000);
        }
        catch { }
        finally {
            setLoading(false);
        }
    }

    const text = (
        clicked
        ? "Erledigt"
        : (
            version.isWeb
            ? "Kopieren"
            : "Einfügen"
        )
    );

    const icon = (
        clicked
        ? <CheckmarkRegular />
        : (
            version.isWeb
            ? <CopyRegular />
            : <InsertRegular />
        )
    );

    return (
        <CustomButton
            text={text}
            icon={icon}
            loading={loading && !clicked}
            color={clicked ? "success" : undefined}
            onClick={clicked ? undefined : clickHandler}
        />
    )
}
import { Badge, Button, Card, CardFooter, CardHeader, Checkbox, Divider, Menu, MenuList, MenuPopover, MenuTrigger, Switch, Text, Tooltip } from "@fluentui/react-components";
import { EyeOffRegular, EyeRegular, MoreVertical48Regular } from "@fluentui/react-icons";
import React from "react";
import useChartUtil from "../../../hooks/useChartUtil";
import useResponsive, { Breakpoint } from "../../../hooks/useResponsive";
import useYearUtil from "../../../hooks/useYearUtil";
import { IChart } from "../../../types/charts.schema";
import Flex from "../container/Flex";
import ChartGroupByBanner from "./ChartGroupByBanner";
import "./ChartItem.css";
import ChartOrientationBanner from "./ChartOrientationBanner";
import ChartPresetBanner from "./ChartPresetBanner";
import ChartPreview from "./ChartPreview";
import ChartYoyBadge from "./ChartYoyBadge";
import DeleteChartButton from "./DeleteChartButton";
import InsertChartButton from "./InsertChartButton";
import UpdateChartForm from "./UpdateChartForm";

export interface IChartItemProps {
    chart: IChart,
    isSelectable?: boolean,
    isSelected?: boolean,
    onSelect?: (chart: IChart) => void,
    isInsertable?: boolean,
    isEditable?: boolean,
    isDeletable?: boolean,
    previewAlwaysVisible?: boolean
}

export default function ChartItem(props: IChartItemProps) {

    const {
        chart,
        isDeletable = true,
        isEditable = true,
        isSelectable = false,
        onSelect,
        isSelected = false,
        isInsertable = true,
        previewAlwaysVisible = false    
    } = props;

    const [previewVisible, setPreviewVisible] = React.useState<boolean>(previewAlwaysVisible);

    const {
        getLabelForYear
    } = useYearUtil();

    const {
        getLabelForChartType
    } = useChartUtil();
   
    const previewToggle = (
        <Tooltip relationship="description" content={previewVisible ? "Vorschau ausblenden" : "Vorschau einblenden"}>
            <Button
                icon={previewVisible ? <EyeOffRegular /> : <EyeRegular />}
                appearance="transparent"
                onClick={() => setPreviewVisible(!previewVisible)}
            />
        </Tooltip>
    );

    return (
        <Card 
            className="w-100" 
            selected={!!(isSelectable && !!onSelect) ? isSelected : undefined}
            floatingAction={
                !!(isSelectable && !!onSelect) 
                ? (
                    <Checkbox 
                        checked={isSelected}
                        onChange={(_, data) => onSelect(chart)}
                    />
                )
                : undefined
            }
        >
            <CardHeader
                header={
                    <Flex row fullWidth align="start" justify="between" wrap>
                        <Flex gap={0}>
                            <Flex row>
                                { chart.title && <Text weight="bold">{chart.title}</Text> }
                                <ChartPresetBanner chart={chart} />
                                <ChartOrientationBanner chart={chart} />
                                <ChartGroupByBanner chart={chart} />
                                <ChartYoyBadge chart={chart} />
                            </Flex>
                            <Text>{getLabelForChartType(chart.type, false)}</Text>
                            
                        </Flex>
                        {
                            !(isSelectable && !!onSelect) && previewToggle
                        }
                    </Flex>
                }
            />
            <Flex fullWidth>
                {
                    previewVisible 
                    ? <ChartPreview chart={chart} />
                    : (
                        <Flex row wrap>
                            {
                                chart.years && chart.years.map(y => <Badge key={y} color="informative" size="small">{getLabelForYear(y)}</Badge>)
                            }
                        </Flex>
                    )
                }
            </Flex>
            <CardFooter>
                <Flex fullWidth row wrap justify="between">
                    {
                        
                        (isSelectable && !!onSelect) 
                        ? previewToggle
                        : <InsertChartButton chart={chart} />
                    }
                    <Flex row gap={0}>
                        {
                            isEditable && <UpdateChartForm chart={chart} />
                        }
                        {
                            (isEditable || isDeletable) && (
                                <Menu>
                                    <MenuTrigger> 
                                        <Button appearance="transparent" icon={<MoreVertical48Regular />} />
                                    </MenuTrigger>
                                    <MenuPopover>
                                        <MenuList>
                                            <Flex fullWidth>
                                                {
                                                    isEditable && <UpdateChartForm chart={chart} isDuplication />
                                                }
                                                <Divider appearance="strong" />
                                                {
                                                    isDeletable && <DeleteChartButton chart={chart} />
                                                }
                                            </Flex>
                                        </MenuList>
                                    </MenuPopover>
                                </Menu>
                            )
                        }

                    </Flex>
                </Flex>
            </CardFooter>
        </Card>
    )
}
import React from "react";
import usePersistance, { StorageKey } from "../../../hooks/usePersistance";
import { ICollection, MappedObject } from "../../../types/collection.types";
import { IMongooseDocument } from "../../../types/mongoose";
import { Button, Menu, MenuItem, MenuList, MenuPopover, MenuTrigger, Spinner, Text } from "@fluentui/react-components";
import IElementProps from "../../../types/element.types";
import { CheckmarkRegular } from "@fluentui/react-icons";

export interface ILocalStateSelectProps<T extends IMongooseDocument> extends IElementProps {
    storageKey: StorageKey, 
    loading: boolean,
    itemsName: string,
    content: ICollection<T>,
    icon: React.ReactElement,
    labelKey: keyof T
}

export default function LocalStateSelect<T extends IMongooseDocument>(props: ILocalStateSelectProps<T>) {

    const {
        icon,
        content,
        labelKey,
        className,
        loading,
        storageKey,
        itemsName
    } = props;

    const {
        value: selectedId,
        updatePersistedValue
    } = usePersistance<string>(storageKey);

    if (loading) return <Button icon={<Spinner size="extra-tiny" />} disabled>Lädt...</Button>
    if (!content) return <Text color="danger">Keine {itemsName} vorhanden</Text>;

    const {
        byId,
        items
    } = content;

    if (!items || !items.length) return <Text color="danger">Keine {itemsName} vorhanden</Text>;
    
    const value = byId[selectedId];
    const label = value ? `${value[labelKey]}` : `${itemsName} auswählen...`;

    return (
        <Menu hasIcons>
            <MenuTrigger>
                <Button
                    className={className}
                    icon={icon}
                    iconPosition="after"
                >
                    { label }
                </Button>
            </MenuTrigger>
            <MenuPopover>
                <MenuList>
                    {
                        items.map(g => (
                            <MenuItem
                                icon={g._id === selectedId ? <CheckmarkRegular /> : undefined}
                                key={g._id}
                                onClick={() => updatePersistedValue(g._id)}
                            >
                                {`${g[labelKey]}`}
                            </MenuItem>
                        ))
                    }
                </MenuList>
            </MenuPopover>
        </Menu>
    )
}
import React from "react";
import { useMappingGroups } from "../../../../state/mappingGroups/useMappingGroups";
import { Badge, Tooltip } from "@fluentui/react-components";
import { AppsAddInRegular, ArrowCircleDownSplitRegular } from "@fluentui/react-icons";

export interface IExtendsGroupBadgeProps {
    extendsGroup?: string;
}

export default function ExtendsGroupBadge(props: IExtendsGroupBadgeProps) {

    const {
        extendsGroup
    } = props;

    const {
        mappingGroupsById
    } = useMappingGroups();

    if (!extendsGroup) return null;
    if (!mappingGroupsById) return null;

    const extendedGroup = mappingGroupsById[extendsGroup];

    if (!extendedGroup) return null;

    return (
        <Tooltip 
            relationship="description"
            content="Dieser Kontenrahmen erweitert einen bestehenden. Das bedeutet, dass dieser Kontenrahmen sämtliche Positionen des originalen Kontenrahmens übernimmt. Neue Positionen können hinzugefügt werden."
        >
            <Badge 
                color="danger"
                icon={<ArrowCircleDownSplitRegular />}
            >
                Erweitert: {extendedGroup.name}
            </Badge>
        </Tooltip>
    )
}
import { ICoordinates } from "../types/slideDeck.schema";

export const SlideSizes = {
    SixteenNine: {
        x: 1280,
        y: 720
    },
    FourThree: {
        x: 960,
        y: 720
    }
}

export default function useSlideUtil(slideSize: ICoordinates) {
    
    const usedSize = slideSize ?? SlideSizes.SixteenNine;
    
    const htmlSlideSizeFactors: ICoordinates = {
        x: 225 / SlideSizes.SixteenNine.x,
        y: 125 / SlideSizes.SixteenNine.y
    }

    const htmlSlideSize: ICoordinates = {
        x: usedSize.x * htmlSlideSizeFactors.x,
        y: usedSize.y * htmlSlideSizeFactors.y
    }

    const getPercentOfSlideSize = (dims: ICoordinates): ICoordinates => {
        return {
            x: dims.x / usedSize.x * 100,
            y: dims.y / usedSize.y * 100
        }
    }

    return {
        htmlSlideSize,
        slideCss: {
            width: htmlSlideSize.x + "px",
            height: htmlSlideSize.y + "px"
        },
        getPercentOfSlideSize
    }
}
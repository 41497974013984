import { Text } from "@fluentui/react-components";
import { EditRegular } from "@fluentui/react-icons";
import React from "react";
import { useMappingTemplates } from "../../../../state/mappings/useMappingTemplates";
import { IMapping, IMappingDocument } from "../../../../types/mapping.schema";
import { IMappingGroupWithMappings } from "../../../../types/mappingGroup.schema";
import ModalDialog from "../../modal/ModalDialog";
import MappingList from "../MappingList";
import { IUpdateMappingFormBase } from "../UpdateMappingsForm";

export interface IEditTemplateAssignmentsFormProps extends IUpdateMappingFormBase{
    entityTypes: Array<string>,
    existingMappings: Array<IMappingDocument>
}

export default function EditTemplateAssignmentsForm(props: IEditTemplateAssignmentsFormProps) {

    const {
        entityTypes,
        existingMappings,
        afterChange,
        customChangeHandler
    } = props;

    const {
        loadingMappingTemplates,
        mappingTemplates
    } = useMappingTemplates();

    const getMapOfExistingMappings = (): Map<string, number> => {
        const result = new Map<string, number>(); 

        if (!existingMappings || !existingMappings.length) return result;

        for (let i = 0; i < existingMappings.length; i++) {
            const m = existingMappings[i];
            if (!m || !m.baseTemplate) continue;
            result.set(m.baseTemplate, i);
        }

        return result;
    }

    const combineTemplatesWithValues = (templates: Array<IMapping>): Array<IMappingDocument> => {
        if (!templates || !templates.length) return [];

        const result = [];

        for (const t of templates) {

            const item = {...t};
            
            item.isTemplate = false;
            item.baseTemplate = t._id;
            
            const index = getMapOfExistingMappings().get(t._id);

            if (index === undefined) {
                result.push(item);
                continue;
            }

            const existing = existingMappings[index];

            if (!existing) {
                result.push(item);
                continue;
            }

            if (!item.isCalculated) item.accounts = existing.accounts;

            result.push(existing);
        }

        return result;
    }

    const getMappingsForEntityTypes = (): Array<IMappingDocument> => {
        
        if (loadingMappingTemplates) return [];
        
        const result = mappingTemplates.filter(m => {
            if (!m) return false;
            if (!m.appliesToEntities || !m.appliesToEntities.length) return true;

            if (!entityTypes || !entityTypes.length) return false;

            return m.appliesToEntities.some(e => entityTypes.includes(e));
        });

        return combineTemplatesWithValues(result);
    }

    const items = getMappingsForEntityTypes();

    return (
        <ModalDialog
            priority={5}
            size="large"
            loading={loadingMappingTemplates}
            text="Vorlagen bearbeiten"
            appearance="primary"
            icon={<EditRegular />}
            title="Vorlagen bearbeiten"
        >
            {
                items && !!items.length
                ? (
                    <MappingList 
                        hideTabs
                        isTemplateUsage
                        customChangeHandler={customChangeHandler}
                        afterChange={afterChange}
                        mappingGroup={{
                            _id: "templates",
                            mappings: items
                        } as IMappingGroupWithMappings}
                    />
                )
                : <Text>Keine Vorlagen verfügbar</Text>
            }
        </ModalDialog>
    )

}
import { DeleteRegular } from "@fluentui/react-icons";
import React from "react";
import { IEntityType } from "../../../types/entityType.schema";
import useApi from "../../../api/useApi";
import { useEntityTypes } from "../../../state/entities/useEntityTypes";
import { useUser } from "../../../state/user/useUser";
import CustomButton from "../button/CustomButton";

export interface IDeleteEntityTypeButtonProps  {
    entityType: IEntityType
}

export default function DeleteEntityTypeButton(props: IDeleteEntityTypeButtonProps) {

    const {
        entityType
    } = props;

    const {
        reloadEntityTypes
    } = useEntityTypes();

    const {
        user    
    } = useUser();

    const {
        entityTypesDelete
    } = useApi();

    const click = async () => {
        const res = await entityTypesDelete(entityType);
        if (!res || !res.success) return;
        await reloadEntityTypes();
    }

    if (!user.isGlobalAdmin) return null;

    return (
        <CustomButton
            color="danger"
            secondStepQuestion="Sicher?"
            text="Typ löschen"
            onClick={click}
            icon={<DeleteRegular />}
        />
    )
}
import React from "react";
import CustomButton from "../../button/CustomButton";
import useApi from "../../../../api/useApi";
import { IMappingGroup } from "../../../../types/mappingGroup.schema";
import { useMappingGroups } from "../../../../state/mappingGroups/useMappingGroups";
import { DeleteRegular } from "@fluentui/react-icons";
import { useUser } from "../../../../state/user/useUser";
import IElementProps from "../../../../types/element.types";

export interface IDeleteMappingGroupButtonProps extends IElementProps {
    mappingGroup: IMappingGroup
}

export default function DeleteMappingGroupButton(props: IDeleteMappingGroupButtonProps) {

    const {
        mappingGroup,
        className
    } = props;

    const {
        reloadMappingGroups
    } = useMappingGroups();

    const {
        mappingGroupsDelete
    } = useApi();

    const {
        user
    } = useUser();

    const click = async () => {
        const res = await mappingGroupsDelete(mappingGroup._id);
        if (!res || !res.success) return;
        await reloadMappingGroups();
    }

    if (!user.isGlobalAdmin && mappingGroup.isGlobalPreset) return null;

    return (
        <CustomButton
            className={className}
            color="danger"
            secondStepQuestion="Sicher?"
            text="Kontenrahmen löschen"
            onClick={click}
            icon={<DeleteRegular />}
        />
    )
}
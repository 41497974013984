import { Input, Spinner, Tab, TabList, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow, TableSelectionCell } from "@fluentui/react-components";
import { DeleteRegular, SearchRegular } from "@fluentui/react-icons";
import React from "react";
import Flex from "../container/Flex";
import ListPaginator from "./ListPaginator";
import "./PaginatedList.css";

type StandardEnum<T> = {
    [id: string]: T | string;
    [nu: number]: T | string
}

export interface IPaginatedListBase<T> {
    canSelect?: boolean,
    selectedKeys?: Array<string>,
    slot?: React.ReactElement,
    slotPosition?: "left" | "right",
    size?: "small" | "medium",
    pageSize?: number,
    onSelect?: (item: T) => void,
    onRemove?: (item: T) => void,
}

export interface IPaginatedListProps<T, X extends StandardEnum<any>> extends IPaginatedListBase<T> {
    headers: Array<string> | ((tab: keyof X) => Array<string>),
    items: Array<T> | ((tab: keyof X) => Array<T>),
    getKey: (item: T, index: number) => string | number,
    renderItem: (item: T) => Array<React.ReactNode>
    itemMatchesFilter?: (filter: string, item: T) => boolean,
    itemMatchesTab?: (tab: keyof X, item: T) => boolean,
    tabs?: X,
    hideTabs?: boolean,
    selectedOnly?: boolean,
    getTabVisible?: (tab: keyof X) => boolean,
    getTabLabel?: (tab: keyof X) => string,
    getTabIcon?: (tab: keyof X) => React.ReactElement,
    filterPlaceholder?: string,
    noItemsPlaceholder?: string
}

export default function PaginatedList<T, X extends StandardEnum<any>>(props: IPaginatedListProps<T, X>) {

    const {
        headers,
        items,
        renderItem,
        tabs,
        canSelect,
        noItemsPlaceholder,
        hideTabs,
        getKey,
        getTabVisible,
        selectedKeys,
        filterPlaceholder,
        selectedOnly,
        getTabIcon,
        getTabLabel,
        itemMatchesFilter,
        itemMatchesTab,
        onRemove,
        onSelect,
        pageSize = 15,
        size,
        slot,
        slotPosition
    } = props;

    const filterTimer = React.useRef<any>();

    const [filtering, setFiltering] = React.useState<boolean>(false);
    const [page, setPage] = React.useState<number>(0);
    const [filter, setFilter] = React.useState<string>("");
    const [tab, setTab] = React.useState<keyof X>(tabs ? Object.keys(tabs)[0] as keyof X : "");
    const [availableElements, setAvailableElements] = React.useState<Array<T>>([]);
    
    const refreshAvailableElements = (resetPage: boolean = true) => {
        const usableItems = typeof items === "function" ? items(tab) : items;

        if (resetPage) setPage(0);

        const elements = (
            usableItems && !!usableItems.length
            ? usableItems.filter(m => {
                if (!!itemMatchesTab && !itemMatchesTab(tab, m)) return false;
                if (!filter || !itemMatchesFilter) return true;
                return itemMatchesFilter(filter, m);
            })
            : []
        )
        
        setAvailableElements(elements);
        setFiltering(false);
    }

    React.useEffect(() => refreshAvailableElements(false), [items]);
    React.useEffect(() => refreshAvailableElements(), [tab]);
    React.useEffect(() => {
        setFiltering(true);
        clearTimeout(filterTimer.current);
        filterTimer.current = setTimeout(() => refreshAvailableElements(), 300);

        return () => clearTimeout(filterTimer.current);
    }, [filter]);

    const currentElements = availableElements.slice(page * pageSize, (page + 1) * pageSize);

    const tabItems = tabs && Object.keys(tabs);
    const tableHeaders = typeof headers === "function" ? headers(tab) : headers;

    const getEmptyContentItem = () => (
        <TableRow>
            <TableCell colSpan={tableHeaders.length + 1}>
                { noItemsPlaceholder || "Keine Positionen gefunden"}
            </TableCell>
        </TableRow>
    );

    const getContent = () => {

        if (!currentElements || !currentElements.length) return [getEmptyContentItem()];

        const result = [];

        for (let i = 0; i < currentElements.length; i++) {

            const m = currentElements[i];

            const itemKey = getKey(m, i);
            const isSelected = !!(canSelect || selectedOnly) && selectedKeys?.includes(itemKey.toString());
            
            // needs to be fiddled with to figure out correct iD 
            if (!isSelected && selectedOnly) return null;

            const items = renderItem(m);
    
            if (!items || !items.length) return null;
            
            const getCells = () => {
                const result = [];
    
                for (const item of items) {
                    if (!item) continue;
                    result.push(<TableCell>{item}</TableCell>)
                }
    
                return result;
            }
    
            result.push(
                <TableRow 
                    key={itemKey + "-paginated-list-item-" + i}
                    onClick={() => isSelected ? onRemove?.(m) : onSelect?.(m)}
                >
                    {
                        canSelect && <TableSelectionCell checked={isSelected} />
                    }
                    {
                        getCells()
                    }
                </TableRow>
            )
        }

        if (!result.length) return [getEmptyContentItem()];

        return result;
    }

    const content = getContent() ?? [];
    const pages = Math.floor((availableElements.length - 1) / pageSize);

    return (
        <Flex fullWidth>
            <Flex fullWidth row justify="between">
                {
                    (!hideTabs && tabItems && !!tabItems.length) && (
                        <TabList 
                            selectedValue={tab} 
                            onTabSelect={(_, data) => setTab(data.value as keyof X)}
                            appearance="subtle"
                            size="small"
                        >
                            {
                                tabItems.map(t => {
                                    const tab = (
                                        <Tab 
                                            key={t}
                                            value={t} 
                                            slot="before" 
                                            icon={getTabIcon ? getTabIcon(t) : undefined} 
                                        >
                                                {getTabLabel(t)}
                                        </Tab>
                                    )

                                    if (!getTabVisible || getTabVisible(t)) return tab;
                                    return null;
                                })
                            }
                        </TabList>
                    )
                }
                {
                    !!itemMatchesFilter && (
                        <Input 
                            size={size}
                            appearance="filled-darker" 
                            contentBefore={filtering && <Spinner size="extra-tiny" />}
                            contentAfter={(
                                filter 
                                ? <DeleteRegular onClick={() => setFilter("")} /> 
                                : <SearchRegular />
                            )} 
                            onChange={(e, val) => setFilter(val.value)} 
                            placeholder={filterPlaceholder || "Suchen..."}
                            style={{minWidth: "300px"}}
                            value={filter}
                        />
                    )
                }
            </Flex>
            <Table size={size}>
                <TableHeader>
                    <TableRow>
                        {
                            canSelect && <TableSelectionCell ></TableSelectionCell>
                        }
                        {
                            tableHeaders.map(h => {
                                if (!h) return null;
                                return (
                                    <TableHeaderCell key={h}>{h}</TableHeaderCell>
                                )
                            })
                        }
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {
                        content
                    }
                </TableBody>
            </Table>
            <Flex fullWidth row justify="between" className="paginated-list-footer">
                {
                    slot ?
                    (
                        slotPosition === "right" 
                        ? <ListPaginator currentPage={page} onPageChange={setPage} totalPages={pages} />
                        : slot
                    )
                    : <div />
                }
                {
                    slot ? 
                    (
                        slotPosition === "left"
                        ? <ListPaginator currentPage={page} onPageChange={setPage} totalPages={pages} />
                        : slot
                    )
                    : <ListPaginator currentPage={page} onPageChange={setPage} totalPages={pages} />
                }
            </Flex>
        </Flex>
    )
}
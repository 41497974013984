import useSWR from "swr";
import { RouteParam, Routes } from "../../api/apiRoutes";
import { IMapping } from "../../types/mapping.schema";

export const useMappingGroupTemplates = (id: string) => {

    const { data, isLoading, mutate } = useSWR<Array<IMapping>>(Routes.mappingGroups.templates.getRoute({ [RouteParam.MappingGroup]: id }));

    return {
        mappingGroupTemplates: data,
        loadingMappingGroupTemplates: isLoading,
        reloadMappingGroupTemplates: mutate
    }
}
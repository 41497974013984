import React from "react";
import useOfficeVersion from "./useOfficeVersion"
import SecureLS from "secure-ls";
import { useAppDispatch, useAppSelector } from "../state/reduxHooks";
import { setLocalState } from "../state/local.state";

export enum StorageKey {
    EntityType = "etype",
    Session = "session",
    DatevUpload = "sessionimport",
    SelectedMappingGroup = "mgroup",
    Palette = "palette",
    ColorMode = "colorMode"
}

export default function usePersistance<T = any>(key: StorageKey, forceLocalStorage: boolean = false, secured: boolean = false, defaultValue: T | null = null) {
    
    const secureLs = new SecureLS();

    const localState = useAppSelector(state => state.local);
    const dispatch = useAppDispatch();
    
    const {
        version
    } = useOfficeVersion();
    
    const getPersistedValue = () => {
        const result = (
            !forceLocalStorage && version?.isWeb ? getCookie() : getLocalStorage()
        ) as T | null;
        return result;
    }

    const setPersistedValue = (value: any, days: number = 31) => {
        if (!forceLocalStorage && version?.isWeb) setCookie(value, days);
        else setLocalStorage(value);
    }
    
    const removePersistedValue = () => {
        dispatch(setLocalState({ [key]: undefined }));

        if (!forceLocalStorage && version?.isWeb) return deleteCookie();
        else return deleteLocalStorage();
    }

    const updatePersistedValue = (value: any, days: number = 31) => {
        setPersistedValue(value, days);
        dispatch(setLocalState({ [key]: value }));
    }

    const getCookie = () => {
        const value = `; ${document.cookie}`;

        const parts = value.split(`; ${key}=`);
        if (!parts || parts.length !== 2) return null;
        return parts.pop()?.split(';').shift() as T;
    }

    const deleteCookie = () => {
        const storedCookie = getCookie();

        if (!storedCookie) return;
        
        const date = new Date(Date.parse("Thu, 01 Jan 1970 00:00:01 GMT"));
        document.cookie = `${key}=; expires=${date.toUTCString()}; domain=${window.location.hostname}; path=/`;
    }

    const deleteLocalStorage = () => {
        if (secured) secureLs.remove(key);
        else window.localStorage.removeItem(key);
    }

    const setCookie = (value: T, days: number = 1) => {
        if (!value) return;
        const date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        document.cookie = `${key}=${value}; expires=${date.toUTCString()}; path=/`;
    }

    const getLocalStorage = (): T | null=> {
        if (secured) {
            try { 
                return secureLs.get(key);
            }
            catch { }

            return null;
        } 

        try {
            const value = window.localStorage.getItem(key);
            if (!value) return null;
            return JSON.parse(value);
        }
        catch (e) {
            console.log(e);
            return null;
        }
    }

    const setLocalStorage = (value: T) => {
        try {
            if (!value) {
                deleteLocalStorage();
                return;
            }
        
            if (secured) {
                secureLs.set(key, value);
                return;
            }

            const storableValue = JSON.stringify(value);
            window.localStorage.setItem(key, storableValue);
        }
        catch (e) {
            console.log(e);
        }
    }

    const stateValue = localState[key];
    const value = getPersistedValue() ?? defaultValue;

    React.useEffect(() => {
        if (!value) return;
        
        if (stateValue) {
            setPersistedValue(stateValue);
            return;
        }

        if (value === stateValue) return;

        dispatch(setLocalState(value));
    }, [])


    return {
        value: (stateValue ?? value) as T,
        updatePersistedValue,
        removePersistedValue
    }
}
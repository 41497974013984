import { Avatar, Badge, Button, Card, CardHeader, Checkbox, Radio, RadioGroup, Text } from "@fluentui/react-components";
import { AddRegular, ChevronDownRegular, ChevronLeftRegular, ChevronRightRegular, ChevronUpRegular, DeleteRegular, SaveRegular, SlideTextRegular } from "@fluentui/react-icons";
import { FieldArray, Formik } from "formik";
import React from "react";
import useApi from "../../../api/useApi";
import useNotifications from "../../../hooks/useModal";
import useOfficeVersion from "../../../hooks/useOfficeVersion";
import { ModalType } from "../../../state/modal/modal.state";
import { useSlideDecks } from "../../../state/slideDecks/useSlideDecks";
import { useUser } from "../../../state/user/useUser";
import { IChart } from "../../../types/charts.schema";
import { ChartSizeMode, ICoordinates, ISlide, ISlideChart, ISlideDeck, ISlideDeckDocument, ISlidePadding } from "../../../types/slideDeck.schema";
import { getId } from "../../../util/mongoUtil";
import CustomButton from "../button/CustomButton";
import ChartSelectDialog from "../charts/ChartSelectDialog";
import Flex from "../container/Flex";
import CheckBox from "../formik/CheckBox";
import FileSelect from "../formik/FileSelect";
import FormikField from "../formik/FormikField";
import FormikRange from "../formik/FormikRange";
import PaddedSlidePreview from "./PaddedSlidePreview";
import SlidePreview from "./SlidePreview";
import "./UpdateSlideDeckForm.css";
import DatevSingleYearDataForm from "../datev/DatevSingleYearDataForm";
import EntityTypeSelect from "../entityTypes/EntityTypeSelect";

export interface IUpdateSlideDeckFormProps {
    slideDeck: ISlideDeck
}

export default function UpdateSlideDeckForm(props: IUpdateSlideDeckFormProps) {
    
    const [presetDetailsExpanded, setPresetDetailsExpanded] = React.useState<boolean>(false);
    const [activeSlide, setActiveSlide] = React.useState<number>(-1);
    const [activeIndex, setActiveIndex] = React.useState<number>(0);

    const previewRef = React.useRef<HTMLDivElement>(null);

    const {
        showNotification
    } = useNotifications();

    const {
        slideDeck
    } = props;

    const {
        reloadSlideDecks
    } = useSlideDecks();
    
    const {
        slideDecksUpdate
    } = useApi();

    const {
        isOpenOutsideOfOffice
    } = useOfficeVersion();

    const {
        user
    } = useUser();

    if (!isOpenOutsideOfOffice) return null;
    if (slideDeck && slideDeck.isGlobalPreset && !user?.isGlobalAdmin) return null;

    const getPaddingAdjustField = (name: keyof ISlidePadding) => (
        <FormikRange 
            min={0}
            max={50}
            hideReset
            formatValue={v => typeof v === "number" && v >= 0 ? `${v.toFixed(2)} %` : ""}
            name={`padding.${name}`}
            defaultValue={0}
        />
    )
    
    return (
        <Formik
            initialValues={{
                isGlobalPreset: slideDeck?.isGlobalPreset ?? false,
                name: slideDeck?.name ?? "",
                presetFile: slideDeck?.presetFile ?? undefined,
                padding: slideDeck?.padding ?? {
                    bottom: 0,
                    left: 0,
                    right: 0,
                    top: 0
                },
                defaultDimensions: slideDeck?.defaultDimensions ?? { x: 1280, y: 720 },
                slides: slideDeck?.slides ?? []
            } as ISlideDeckDocument}
            onSubmit={async (values) => {
                if (values.slides && !!values.slides.length) values.slides = values.slides.map(s => {
                    const slide = {...s};
                    if (s.charts && !!s.charts.length) {
                        slide.charts = [];
    
                        s.charts.forEach(c => {
                            if (!c || !c.chart) return;
                            const id = getId(c.chart);
                            if (!id) return;
                            const chart = {...c};
                            chart.chart = getId(c.chart) as any;
                            slide.charts.push(chart);
                        });
                    }
                    return slide;
                });
    
                const res = (await slideDecksUpdate(values as ISlideDeck)).success;
    
                if (!res) return;
    
                await reloadSlideDecks();
                
                showNotification({ text: "Präsentation erfolgreich gespeichert!", type: ModalType.Success, title: "Gespeichert" });
            }}
        >
            {
                (formik) => {

                    const getDimensionPresetBadge = (x: number, y: number, label: string) => (
                        <Badge
                            color={formik.values.defaultDimensions.x === x && formik.values.defaultDimensions.y === y ? "brand" : "informative"}
                            onClick={() => formik.setFieldValue("defaultDimensions", { x: x, y: y } as ICoordinates)} 
                        >
                            {label}
                        </Badge>
                    )

                    const getDimensionsField = (name: keyof ICoordinates) => (
                        <FormikRange 
                            min={0}
                            max={name === "x" ? 1280 : 720}
                            label={name === "x" ? "Breite" : "Höhe"}
                            hideReset
                            formatValue={v => typeof v === "number" && v >= 0 ? `${v}px` : ""}
                            name={`defaultDimensions.${name}`}
                            defaultValue={0}
                        />
                    )

                    
                    return (
                        <Flex fullWidth fullHeight>
                            <Flex fullWidth justify="between" row>
                                <Text weight="bold">Metadaten</Text>
                                <CustomButton 
                                    onClick={async () => await formik.submitForm()} 
                                    icon={<SaveRegular />} 
                                    loading={formik.isSubmitting} 
                                    text="Speichern" 
                                />
                            </Flex>
                            <Card className="w-100">
                                <FormikField name="name" label="Name der Präsentation" placeholder="Name" />
                                {
                                    (!!user && user.isGlobalAdmin) && <CheckBox name="isGlobalPreset" label="Diese Präsentation kann von jedem Kunden genutzt werden" />
                                }
                                <Flex fullWidth>
                                    <Flex row onClick={() => setPresetDetailsExpanded(!presetDetailsExpanded)} fullWidth justify="between">
                                        <Text weight="bold">Vorlagendetails</Text>
                                        <Badge 
                                            color="informative"
                                            size="extra-large"
                                            icon={presetDetailsExpanded ?  <ChevronUpRegular /> : <ChevronDownRegular />}
                                        >
                                            {
                                                presetDetailsExpanded 
                                                ? "Einstellungen schließen" 
                                                : "Einstellungen öffnen"
                                            }
                                        </Badge>
                                    </Flex>
                                    {
                                        presetDetailsExpanded && (
                                            
                                            <Flex>
                                                <Card className="w-100" appearance="outline">
                                                    <Text weight="bold">Dateivorlage</Text>
                                                    <FileSelect 
                                                        value={formik.values.presetFile}
                                                        onChange={v => { console.log(v); formik.setFieldValue("presetFile", v) }}
                                                        onClear={() => formik.setFieldValue("presetFile", undefined)}
                                                        formats="application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation"
                                                    />
                                                </Card>
                                                <Card className="w-100" appearance="outline">
                                                    <Flex gap={1}>
                                                        <Text weight="bold">Dimensionen und Seitenverhältnis</Text>
                                                        <Text size={200}>Hier können die Dimensionen einer Folie in Pixeln angegeben werden. Das Seitenverhältnis und die Auflösung wird vom Add-In nicht angepasst, vielmehr müssen hier die Werte der Vorlagendatei eingetragen werden, um eine korrekte Positionierung der Diagramme zu ermöglichen. Bei Standardfolienmaßen können die Vorlagenknöpfe verwendet werden. Meistens ist dies 16/9.</Text>
                                                    </Flex>
                                                    <Flex>
                                                        <Text>Vorlagen</Text>
                                                        <Flex row>
                                                            {getDimensionPresetBadge(1280, 720, "16:9")}
                                                            {getDimensionPresetBadge(960, 720, "4:3")}
                                                        </Flex>
                                                    </Flex>
                                                    <Flex fullWidth>
                                                        { getDimensionsField("x") }
                                                        { getDimensionsField("y") }
                                                    </Flex>
                                                </Card>
                                                <Card className="w-100" appearance="outline">
                                                    <Flex gap={1}>
                                                        <Text weight="bold">Abstand zum Rand</Text>
                                                        <Text size={200}>Hier kann der Abstand zum Rand des Inhaltsbereichs angegeben werden. Nützlich für Folienvorlagen, bei denen das Design sowie die Position der Titelzeile den Inhaltsbereich begrenzt.</Text>
                                                    </Flex>
                                                    <Flex fullWidth>
                                                        <Flex fullWidth align="center" justify="center">
                                                            {getPaddingAdjustField("top")}
                                                        </Flex>
                                                        <Flex fullWidth row align="center" justify="center">
                                                            {getPaddingAdjustField("left")}
                                                            <PaddedSlidePreview padding={formik.values.padding} slideSize={formik.values.defaultDimensions}>
                                                                <Text size={700} italic className="position-absolute top-50 start-50 translate-middle">Inhalt</Text>
                                                            </PaddedSlidePreview>
                                                            {getPaddingAdjustField("right")}
                                                        </Flex>
                                                        <Flex fullWidth align="center" justify="center">
                                                            {getPaddingAdjustField("bottom")}
                                                        </Flex>
                                                    </Flex>
                                                </Card>
                                            </Flex>
                                        )
                                    }
                                </Flex>
                            </Card>
                            <FieldArray name="slides">
                                {
                                    arrayHelpers => {
                                        const addSlide = () => {
                                            arrayHelpers.push<ISlide>({
                                                charts: [],
                                                visibleForEntities: [],
                                                index: -1
                                            })
                                            setActiveSlide(formik.values.slides.length - 1);
                                        }

                                        const getSlideAtIndex = (i: number) => {
                                            if (i < 0) return null;
                                            if (!formik.values.slides || !formik.values.slides.length) return null;
                                            if (i >= formik.values.slides.length) return null;
                                            return formik.values.slides[i];                                        
                                        }
                                        
                                        const slide = getSlideAtIndex(activeSlide);
                                        const firstSlide = getSlideAtIndex(0);
                                        let slideIndex = firstSlide?.index || 0;

                                        return (
                                            <Flex fullWidth>
                                                <Flex row fullWidth justify="between">
                                                    <Text weight="bold">Folien</Text>
                                                    <Button
                                                        appearance="transparent"
                                                        icon={<AddRegular />}
                                                        onClick={addSlide}
                                                    >
                                                        Folie hinzufügen
                                                    </Button>
                                                </Flex>
                                                <Flex row fullWidth className="position-relative ps-5 pe-5">
                                                    <Card
                                                        className="position-absolute start-0 h-100" 
                                                        style={{zIndex: 99999}} 
                                                        onClick={() => { if (previewRef.current) previewRef.current.scrollLeft -= 100; }}
                                                    >
                                                        <Flex justify="center" align="center" fullWidth fullHeight>
                                                            <ChevronLeftRegular />
                                                        </Flex>
                                                    </Card>
                                                    <div ref={previewRef} className="w-100 d-flex flex-row gap-3 slide-preview-container">
                                                        {
                                                            !!formik.values.slides && !!formik.values.slides.length && formik.values.slides.map((slide, index) => {
                                                                
                                                                slideIndex = slide.index >= 0 ? slide.index : slideIndex + 1;

                                                                return (
                                                                    <SlidePreview 
                                                                        onClick={i => { setActiveSlide(index); setActiveIndex(i); }} 
                                                                        active={activeSlide === index} 
                                                                        totalItems={formik.values.slides.length}
                                                                        slideSize={formik.values.defaultDimensions}
                                                                        padding={formik.values.padding}
                                                                        key={index} 
                                                                        slide={slide}
                                                                        slideIndex={slideIndex} 
                                                                        arrayIndex={index}
                                                                        swap={arrayHelpers.swap}
                                                                    />
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                    <Card
                                                        className="position-absolute end-0 h-100" 
                                                        style={{zIndex: 99999}} 
                                                        onClick={() => { if (previewRef.current) previewRef.current.scrollLeft += 100; }}
                                                    >
                                                        <Flex justify="center" align="center" fullWidth fullHeight>
                                                            <ChevronRightRegular />
                                                        </Flex>
                                                    </Card>
                                                </Flex>
                                                {
                                                    slide && (
                                                        <Card className="w-100" appearance="filled-alternative">
                                                            <CardHeader
                                                                image={<Avatar icon={<SlideTextRegular />} />}
                                                                header={
                                                                    <Flex row fullWidth justify="between">
                                                                        <Flex gap={0}>
                                                                            <Text weight="bold">Folie {activeIndex}</Text>
                                                                            { slide.index > 0 && <Text size={200}>wird eingesetzt auf Folie Nummer {slide.index}</Text>}
                                                                        </Flex>
                                                                        <Button 
                                                                            icon={<DeleteRegular />}
                                                                            appearance="transparent"
                                                                            onClick={() => {
                                                                                arrayHelpers.remove(activeSlide)
                                                                                setActiveSlide(-1)
                                                                            }}                                                                    
                                                                        />
                                                                    </Flex>
                                                                }
                                                            />
                                                            <Card className="w-100" appearance={slide.index > 0 ? "filled" : "subtle"}>
                                                                <Flex>
                                                                    <Flex gap={1}>
                                                                        <Checkbox 
                                                                            checked={slide.index > 0}
                                                                            label="Abweichende Foliennummer"
                                                                            onChange={(_, val) => {
                                                                                if (val.checked) formik.setFieldValue(`slides.${activeSlide}.index`, activeSlide + 1);
                                                                                else formik.setFieldValue(`slides.${activeSlide}.index`, -1);
                                                                            }}
                                                                        />
                                                                        <Text size={200}>Setzt die Diagramme und sonstigen Einstellungen für diese Folie auf der angegebenen ein anstelle der Position in der Folienauflistung zu entsprechen.</Text>
                                                                        {
                                                                            slide.index > 0 && <FormikField name={`slides.${activeSlide}.index`} type="number" />
                                                                        }
                                                                    </Flex>
                                                                    <EntityTypeSelect 
                                                                        selectedEntityTypes={slide.visibleForEntities}
                                                                        description="Firmentypen, für die diese Folie sichtbar sein soll. Bei keiner Auswahl ist die Folie für alle Firmentypen sichtbar."
                                                                        label="Sichtbarkeit"
                                                                        onChange={types => formik.setFieldValue(`slides.${activeSlide}.visibleForEntities`, types)}
                                                                    />
                                                                </Flex>
                                                            </Card>
                                                            <FieldArray name={`slides.${activeSlide}.charts`}>
                                                                {
                                                                    arrayHelpers => (
                                                                        <Flex fullWidth>
                                                                            <Flex row fullWidth justify="between">
                                                                                <Text weight="bold">Diagramme</Text>
                                                                                <Button
                                                                                    icon={<AddRegular />}
                                                                                    appearance="transparent"
                                                                                    onClick={() => {
                                                                                        arrayHelpers.push<ISlideChart>({
                                                                                            chart: {} as IChart,
                                                                                            position: {
                                                                                                x: slide.charts?.length * 3 || 0,
                                                                                                y: slide.charts?.length * 3 || 0
                                                                                            },
                                                                                            size: {
                                                                                                sizeMode: ChartSizeMode.width,
                                                                                                value: 400
                                                                                            }
                                                                                        })
                                                                                    }}
                                                                                >
                                                                                </Button>
                                                                            </Flex>
                                                                            <Flex fullWidth>
                                                                                {
                                                                                    slide.charts && !!slide.charts.length
                                                                                    ? slide.charts.map((slideChart, chartIndex) => (
                                                                                        <Flex fullWidth key={chartIndex} gap={3}>
                                                                                            <Card className="w-100">
                                                                                                <ChartSelectDialog
                                                                                                    className="w-100"
                                                                                                    onRemove={() => arrayHelpers.remove(chartIndex)}
                                                                                                    onSelect={v => formik.setFieldValue(`slides.${activeSlide}.charts.${chartIndex}.chart`, v)}
                                                                                                    selectedItem={typeof slideChart.chart === "string" ? slideChart.chart : slideChart.chart?._id}
                                                                                                />
                                                                                                <Flex fullWidth row>
                                                                                                    <Flex fullWidth>
                                                                                                        <Text>Position</Text>
                                                                                                        <FormikRange 
                                                                                                            min={-1}
                                                                                                            max={100}
                                                                                                            formatValue={v => typeof v === "number" && v >= 0 ? `${v.toFixed(2)} %` : ""}
                                                                                                            name={`slides.${activeSlide}.charts.${chartIndex}.position.x`} 
                                                                                                            label="X"
                                                                                                            defaultValue={-1}
                                                                                                        />
                                                                                                        <FormikRange
                                                                                                            min={-1}
                                                                                                            max={100}
                                                                                                            formatValue={v => typeof v === "number" && v >= 0 ? `${v.toFixed(2)} %` : ""}
                                                                                                            name={`slides.${activeSlide}.charts.${chartIndex}.position.y`} 
                                                                                                            defaultValue={-1}
                                                                                                            label="Y" 
                                                                                                        />
                                                                                                    </Flex>
                                                                                                    <Flex fullWidth>
                                                                                                        <Text>Größe</Text>
                                                                                                        <Flex fullWidth>
                                                                                                            <RadioGroup
                                                                                                                onChange={(_, data) => formik.setFieldValue(`slides.${activeSlide}.charts.${chartIndex}.size.sizeMode`, data.value)}
                                                                                                                value={slideChart.size?.sizeMode}
                                                                                                                layout="horizontal"
                                                                                                            >
                                                                                                                <Radio
                                                                                                                    value={ChartSizeMode.height}
                                                                                                                    label="Höhe"
                                                                                                                />
                                                                                                                <Radio
                                                                                                                    value={ChartSizeMode.width}
                                                                                                                    label="Breite"
                                                                                                                />
                                                                                                            </RadioGroup>
                                                                                                        </Flex>
                                                                                                        <FormikField 
                                                                                                            name={`slides.${activeSlide}.charts.${chartIndex}.size.value`} 
                                                                                                            type="number" 
                                                                                                            defaultValue={0}
                                                                                                            placeholder="Wert" 
                                                                                                        />
                                                                                                    </Flex>
                                                                                                </Flex>
                                                                                            </Card>
                                                                                        </Flex>
                                                                                    ))
                                                                                    : <Text>Keine Diagramme</Text>
                                                                                }
                                                                            </Flex>
                                                                        </Flex>
                                                                    )
                                                                }
                                                            </FieldArray>
                                                        </Card>
                                                    )
                                                }
                                                
                                            </Flex>
                                        )
                                    }
                                }
                            </FieldArray>
                        </Flex>
                    )
                }
            }
        </Formik>
    )

}
import { ICustomer } from "./customer.schema";
import { IMappingGroup } from "./mappingGroup.schema";
import { IMongooseDocument } from "./mongoose";

export enum BalanceIndicator { 
    Credit = "credit",
    Debit = "debit"
}

export enum Year {
    Current = "current",
    First = "first",
    Second = "second",
    Third = "third",
    Fourth = "fourth",
}

export enum MappingCalculationElementType {
    Operand = "operand",
    Operator = "operator"
}

export enum Operator {
    Add = "+",
    Subtract = "-",
    Multiply = "*",
    Divide = "/",
    LeftParenthesis = "(",
    RightParenthesis = ")"
}

export enum CalculationElementValueSource {
    Mappings = "mappings",
    Accounts = "accounts",
    FixedValue = "fixedValue"
}

export interface IMappingAccount {
    accountFrom: number,
    accountTo?: number
}

export interface IMappingCalculationElementBase {
    type: MappingCalculationElementType,
    operator: Operator
}

export interface IMappingCalculationElement extends IMappingCalculationElementBase {
    valueSource: CalculationElementValueSource,
    value?: number,
    mappings?: string[],
    accountFrom?: number,
    accountTo?: number
}

export enum CalculationResultType {
    Currency = "currency",
    Percentage = "percentage",
    Number = "number"
}

export interface IMappingBase {
    name: string,
    shortName: string,
    indicator: BalanceIndicator,
    isCalculated: boolean,
    isTemplate: boolean,
    baseTemplate: string,
    appliesToEntities: Array<string>,
    resultType: CalculationResultType,    
    resultIsDeltaToLastYear: boolean
}    

export interface IMappingDocument extends IMappingBase {
    accounts: IMappingAccount[],
    calculationSteps: IMappingCalculationElement[],
}

export interface ILightweightMapping extends IMappingBase, IMongooseDocument {}

export interface IMapping extends IMappingDocument, IMongooseDocument {  
    group: IMappingGroup,
    customer: ICustomer
}
import React from "react";
import { IMappingGroup, IMappingGroupDocument, IMappingGroupImport } from "../../../../types/mappingGroup.schema";
import ModalForm from "../../modal/ModalForm";
import useApi from "../../../../api/useApi";
import Flex from "../../container/Flex";
import FormikField from "../../formik/FormikField";
import { Button, Card, MenuItem, Radio, RadioGroup, Text, Tooltip } from "@fluentui/react-components";
import { AddRegular, ArrowCircleDownSplitRegular, CopyRegular, DeleteRegular, Edit16Regular, EditRegular, QuestionCircleRegular, QuestionRegular } from "@fluentui/react-icons";
import { Field, FieldArray } from "formik";
import { useMappingGroups } from "../../../../state/mappingGroups/useMappingGroups";
import { useUser } from "../../../../state/user/useUser";
import CheckBox from "../../formik/CheckBox";
import IElementProps from "../../../../types/element.types";
import CsvSelect from "../../csv/CsvSelect";
import { ParseStepResult } from "papaparse";
import { BalanceIndicator, CalculationResultType, IMappingDocument } from "../../../../types/mapping.schema";
import { ExternalDataSourceColumnMap } from "../../../../hooks/useNumberImport";
import BalanceIndicatorRadioGroup from "../BalanceIndicatorRadioGroup";
import EntityTypeSelect from "../../entityTypes/EntityTypeSelect";
import ModalDialog from "../../modal/ModalDialog";
import EditTemplateAssignmentsForm from "../templates/EditTemplateAssignmentsForm";
import CustomButton from "../../button/CustomButton";
import { getId } from "../../../../util/mongoUtil";
import ExtendsGroupBadge from "./ExtendsGroupBadge";

export interface IUpdateMappingGroupFormProps extends IElementProps {
    mappingGroup?: IMappingGroup,
    isDuplication?: boolean,
    isChildCreation?: boolean
}

export interface IExternalMappingImport {
    accountFrom: string,
    accountTo: string,
    groupName: string
}

export const MappingImportColumns: ExternalDataSourceColumnMap<IExternalMappingImport> = {
    datev: {
        accountFrom: "Konto von",
        accountTo: "Konto bis",
        groupName: "Beschriftung"
    }
}

export default function UpdateMappingGroupForm(props: IUpdateMappingGroupFormProps) {
    
    const {
        mappingGroup, 
        isDuplication, 
        isChildCreation,
        className 
    } = props;

    const [hasMassDataImport, setHasMassDataImport] = React.useState<boolean>(false);

    const {
        reloadMappingGroups
    } = useMappingGroups();

    const {
        mappingGroupsUpdate,
        mappingGroupsDuplicate,
        mappingGroupsCreate
    } = useApi();

    const {
        user
    } = useUser();

    const getText = () => {
        if (!mappingGroup) return "Neuer Kontenrahmen";

        if (isDuplication) return "Duplizieren";
        if (isChildCreation) return "In Tochter-Kontenrahmen überführen";

        return "Bearbeiten";
    }

    const getIcon = () => {
        if (!mappingGroup) return <AddRegular />;
        if (isDuplication) return <CopyRegular />;
        if (isChildCreation) return <ArrowCircleDownSplitRegular />;
        return <EditRegular />;
    }

    const text = getText();
    const icon = getIcon();

    if (mappingGroup && mappingGroup.isGlobalPreset && !user?.isGlobalAdmin && !isDuplication) return null;
    
    return (
        <ModalForm
            appearance={mappingGroup ? "subtle" : undefined}
            text={text}
            className={className}
            title={text}
            icon={icon}
            initialValues= {{
                isGlobalPreset: mappingGroup?.isGlobalPreset ?? false,
                name: mappingGroup?.name ?? "",
                mappings: [],
                extendsGroup: isChildCreation ? mappingGroup?._id : mappingGroup?.extendsGroup,
                appliesTo: mappingGroup?.appliesTo ?? [],
                indicators: []
            } as IMappingGroupImport}
            enableReinitialize
            onSubmit={async (values) => {
                const res = (
                    (isChildCreation || !mappingGroup)
                    ? await mappingGroupsCreate(values)
                    : (
                        isDuplication
                        ? await mappingGroupsDuplicate(mappingGroup._id, values)
                        : await mappingGroupsUpdate(mappingGroup._id, values)
                    )
                ).success;

                if (res) await reloadMappingGroups();
                return res;
            }}
        >
            {
                formik => (
                    <Flex fullWidth gap={3}>
                        <ExtendsGroupBadge extendsGroup={formik.values.extendsGroup} />
                        <FormikField name="name" label="Name des Kontenrahmens" />
                        {
                            (!!user && user.isGlobalAdmin) && (
                                <Flex fullWidth gap={0}>
                                    <Text>Vorlagenstatus</Text>
                                    <CheckBox name="isGlobalPreset" label="Dieser Kontenrahmen kann von jedem Kunden genutzt werden" />
                                </Flex>
                            )
                        }
                        <Flex fullWidth>
                            {
                                (!isChildCreation && !mappingGroup?.extendsGroup) && (
                                    <Flex fullWidth>
                                        <Flex row fullWidth>
                                            <Text>Firmentypen auswählen</Text>
                                            <Tooltip 
                                                relationship="description" 
                                                content={(
                                                    <Flex>
                                                        <Text weight="bold">Vorlagenkontengruppen</Text>
                                                        <Text>
                                                            Dies hat einen Einfluss darauf, welche Vorlagenkontengruppen für diesen Kontenrahmen verfügbar sind.<br/><br/>
                                                            Durch die Verwendung der Vorlagenkontengruppe können Sie standardisierte Diagramme ungeachtet der tatsächlichen Kontenzuordnung verwenden.
                                                        </Text>
                                                    </Flex>
                                                )}
                                            >
                                                <QuestionCircleRegular />
                                            </Tooltip>
                                        </Flex>
                                        <EntityTypeSelect 
                                            onChange={(entityTypes) => formik.setFieldValue("appliesTo", entityTypes)}
                                            selectedEntityTypes={formik.values.appliesTo}
                                        />
                                    </Flex>
                                )
                            }
                            {
                                (!!formik.values.appliesTo && !!formik.values.appliesTo.length && !isChildCreation && !mappingGroup?.extendsGroup) && (
                                    <Flex fullWidth align="end">
                                        <EditTemplateAssignmentsForm
                                            existingMappings={formik.values.mappings}
                                            customChangeHandler={(m) => {
                                                const i = formik.values.mappings.findIndex(e => getId(e.baseTemplate) === getId(m.baseTemplate));
                                                const newMappings = [...formik.values.mappings];

                                                if (i < 0) newMappings.push(m);
                                                else newMappings[i] = m;

                                                formik.setFieldValue("mappings", newMappings);
                                                return true;
                                            }}
                                            entityTypes={formik.values.appliesTo}
                                        />
                                    </Flex>
                                )
                            }
                        </Flex>
                        {
                            (!isChildCreation && !mappingGroup?.extendsGroup) && (
                                <Card appearance="outline" className="w-100">
                                    <Flex gap={0}>
                                        <Text>Massendaten-Import</Text>
                                        <Flex fullWidth row>
                                            <Text size={200}>Übernimmt aus einer Export-Datei alle Konten Ihres Kontenrahmens.</Text>
                                            <Tooltip relationship="description" content="Für den Export einer CSV-Datei können Sie eine Tabelle in Excel über Datei > Exportieren > Dateityp ändern > CSV passend ausgeben.">
                                                <QuestionCircleRegular />    
                                            </Tooltip>                            
                                        </Flex>
                                    </Flex>
                                    <CsvSelect
                                        bold={false}
                                        processResult={(parsed: ParseStepResult<any>[]) => {
                                            const data = parsed.map(r => r.data);
                                            const result: Array<IMappingDocument> = [];

                                            let lastName = "";
                                            let currentItem: IMappingDocument | undefined = undefined;

                                            for (const d of data) {
                                                try {

                                                    if (!d) continue;
            
                                                    const accountFrom = parseInt(d[MappingImportColumns.datev.accountFrom]);
                                                    const accountTo = parseInt(d[MappingImportColumns.datev.accountTo]);
            
                                                    const name = d[MappingImportColumns.datev.groupName];
                                                    if (!name && !lastName) continue;
                                                    
                                                    if (name && name !== lastName) {
                                                        lastName = name;
                                                        if (currentItem) result.push(currentItem);
                                                        currentItem = {
                                                            baseTemplate: "",
                                                            appliesToEntities: [],
                                                            accounts: [],
                                                            isTemplate: false,
                                                            resultIsDeltaToLastYear: false,
                                                            resultType: CalculationResultType.Currency,
                                                            indicator: BalanceIndicator.Credit,
                                                            isCalculated: false,
                                                            calculationSteps: [],
                                                            name,
                                                            shortName: ""
                                                        }
                                                    }
            
                                                    if (!currentItem) continue;
            
                                                    
                                                    currentItem.accounts.push({
                                                        accountFrom,
                                                        accountTo: accountTo === accountFrom ? undefined : accountTo
                                                    });
                                                }
                                                catch (err) { console.log(err); }
                                            }

                                            if (!!currentItem) result.push(currentItem);

                                            const realResult: Array<IMappingDocument> = [];

                                            for (const r of result) {
                                                if (!r.accounts || !r.accounts.length) continue;

                                                let startOfRange = -1;
                                                let endOfRange = -1;

                                                for (const acc of r.accounts) {
                                                    try {
                                                        if (startOfRange === -1) {
                                                            startOfRange = acc.accountFrom;
                                                            endOfRange = acc.accountTo ?? acc.accountFrom;
                                                            continue;
                                                        }
                                                        
                                                        if (acc.accountFrom === endOfRange + 1) {
                                                            endOfRange = acc.accountTo ?? acc.accountFrom;
                                                            continue;
                                                        }
            
                                                        realResult.push({
                                                            accounts: [{ accountFrom: startOfRange, accountTo: endOfRange }],
                                                            baseTemplate: "",
                                                            appliesToEntities: [],
                                                            indicator: BalanceIndicator.Credit,
                                                            resultIsDeltaToLastYear: false,
                                                            isCalculated: false,
                                                            resultType: CalculationResultType.Currency,
                                                            calculationSteps: [],
                                                            name: r.name,
                                                            isTemplate: false,
                                                            shortName: r.shortName
                                                        });
            
                                                        startOfRange = acc.accountFrom;
                                                        endOfRange = acc.accountTo ?? acc.accountFrom;
                                                    }
                                                    catch (err) { console.log(err); }
                                                }

                                                realResult.push({
                                                    accounts: [{ accountFrom: startOfRange, accountTo: endOfRange }],
                                                    indicator: BalanceIndicator.Credit,
                                                    resultIsDeltaToLastYear: false,
                                                    baseTemplate: "",
                                                    appliesToEntities: [],
                                                    resultType: CalculationResultType.Currency,
                                                    isCalculated: false,
                                                    calculationSteps: [],
                                                    name: r.name,
                                                    isTemplate: false,
                                                    shortName: r.shortName
                                                });
                                            }

                                            formik.setFieldValue("mappings", realResult);
                                            setHasMassDataImport(true);
                                        }}
                                        onClear={() => {
                                            formik.setFieldValue("mappings", [])
                                            setHasMassDataImport(false);
                                        }}
                                    />
                                    {
                                        hasMassDataImport && (
                                            <FieldArray name="indicators">
                                                {
                                                    (arrayHelpers) => (
                                                        <Flex fullWidth>
                                                            <Flex row fullWidth justify="between">
                                                                <Flex>
                                                                    <Text>Bilanztypen für Kontenbereiche</Text>
                                                                    <Text size={200}>
                                                                        Legen Sie für den Massendatenimport Bereiche für Bilanztypen fest, etwa Aktiva, Passiva oder verschiedene Ertragsgruppen.
                                                                        Einige Bereiche werden eventuell bereits durch die ausgewählten Vorlagen abgedeckt. Diese Bereiche werden dann übersprungen.
                                                                    </Text>
                                                                </Flex>
                                                                <Button onClick={() => arrayHelpers.push({ from: 0, to: 0, indicator: BalanceIndicator.Credit })} icon={<AddRegular />} />
                                                            </Flex>
                                                            <Flex fullWidth>
                                                                {
                                                                    formik.values.indicators && !!formik.values.indicators.length
                                                                    ? formik.values.indicators.map((i, index) => (
                                                                        <Card appearance="outline" className="w-100">
                                                                            <Flex row>
                                                                                <FormikField name={`indicators.${index}.from`} label="Von Konto" />
                                                                                <FormikField name={`indicators.${index}.to`} label="Bis Konto" />
                                                                                <BalanceIndicatorRadioGroup
                                                                                    fieldName={`indicators.${index}.indicator`}
                                                                                    value={i.indicator}
                                                                                    label="Bilanztyp"
                                                                                />
                                                                                <CustomButton 
                                                                                    onClick={() => arrayHelpers.remove(index)} 
                                                                                    icon={<DeleteRegular />}  
                                                                                    color="danger"
                                                                                />
                                                                            </Flex>
                                                                        </Card>
                                                                    ))
                                                                    : <Text>Keine Gruppen hinterlegt</Text>
                                                                }
                                                            </Flex>
                                                        </Flex>
                                                    )
                                                }
                                            </FieldArray>
                                        )
                                    }
                                </Card>
                            )
                        }
                    </Flex>
                )
            }
        </ModalForm>
    )
}
import { Button, Menu, MenuItem, MenuList, MenuPopover, MenuTrigger, Spinner, Text } from "@fluentui/react-components";
import { CalculatorRegular, CheckmarkRegular } from "@fluentui/react-icons";
import React from "react";
import usePersistance, { StorageKey } from "../../../../hooks/usePersistance";
import { useMappingGroups } from "../../../../state/mappingGroups/useMappingGroups";
import IElementProps from "../../../../types/element.types";
import LocalStateSelect from "../../select/LocalStateSelect";

export default function MappingGroupSelect({ className } : IElementProps) {

    const {
        loadingMappingGroups,
        mappingGroups,
        mappingGroupsById
    } = useMappingGroups();

    return (
        <LocalStateSelect
            content={{
                byId: mappingGroupsById,
                items: mappingGroups
            }}
            labelKey="name"
            icon={<CalculatorRegular />}
            itemsName="Zuordnung"
            loading={loadingMappingGroups}
            storageKey={StorageKey.SelectedMappingGroup}
            className={className}
        />
    )
}
import { Badge, Spinner, Text, Tooltip } from "@fluentui/react-components";
import { ArrowCircleDownSplitRegular, BuildingBankRegular, CalculatorRegular, ColorRegular, InfoRegular } from "@fluentui/react-icons";
import React from "react";
import usePersistance, { StorageKey } from "../../../hooks/usePersistance";
import { useMappingGroupTemplates } from "../../../state/mappingGroups/useMappingGroupTemplates";
import { useMappingGroups } from "../../../state/mappingGroups/useMappingGroups";
import { CalculationResultType, IMapping } from "../../../types/mapping.schema";
import { IMappingGroupWithMappings } from "../../../types/mappingGroup.schema";
import Flex from "../container/Flex";
import EntityTypeSelect from "../entityTypes/EntityTypeSelect";
import PaginatedList, { IPaginatedListBase } from "../list/PaginatedList";
import BalanceTypeBadge from "./BalanceTypeBadge";
import DeleteMappingButton from "./DeleteMappingButton";
import MappingPreviewForGroup from "./MappingPreviewForGroup";
import UpdateMappingForm, { IUpdateMappingFormBase, IWithTemplateProps } from "./UpdateMappingsForm";
import CalculateMappingTest from "./calculations/CalculateMappingTest";
import { getId } from "../../../util/mongoUtil";

export interface IMappingListPropsBase extends IPaginatedListBase<IMapping> {
    mappingGroup?: IMappingGroupWithMappings
}

export interface IMappingListProps extends IPaginatedListBase<IMapping>, IMappingListPropsBase, IWithTemplateProps, IUpdateMappingFormBase {
    hideTabs?: boolean,
    selectedOnly?: boolean
}

export enum MappingListTab {
    All = "all",
    Calculated = "calculated",
    ParentGroup = "parentGroup",
    Templates = "templates",
    NotCalculated = "notCalculated"
}

export default function MappingList(props: IMappingListProps) {

    const {
        mappingGroup: selectedMappingGroup,
        canSelect,
        isTemplateEditing,
        customChangeHandler,
        isTemplateUsage,
        onRemove,
        size = "medium",
        onSelect,
        hideTabs,
        slot,
        selectedOnly,
        pageSize = 20,
        selectedKeys,
        slotPosition
    } = props;

    const {
        value: currentGroupId
    } = usePersistance<string>(StorageKey.SelectedMappingGroup);

    const {
        mappingGroupsById
    } = useMappingGroups();

    const {
        loadingMappingGroupTemplates,
        mappingGroupTemplates,
        reloadMappingGroupTemplates
    } = useMappingGroupTemplates(selectedMappingGroup?._id);

    const getMappingGroup = () => {

        const group = selectedMappingGroup ?? mappingGroupsById[currentGroupId];

        if (loadingMappingGroupTemplates) return group;

        if (!mappingGroupTemplates || !mappingGroupTemplates.length) return group;

        const result = {...group};

        result.mappings = [
            ...group.mappings,
            ...mappingGroupTemplates
        ];

        return result;
    }
    
    const mappingGroup = getMappingGroup();

    const getIcon = (t: CalculationResultType) => {
        switch (t) {
            case CalculationResultType.Percentage: return "%";
            case CalculationResultType.Number: return "#";
            default: return "€";
        }
    }

    const getHeaders = (t: string) => {
        const result = [];

        result.push("Name");
        result.push("Typ");

        if (t === MappingListTab.Calculated || isTemplateEditing) result.push("Berechnung");
        else result.push("Konten");

        if (!canSelect) result.push("Aktionen");

        return result;
    }

    if (loadingMappingGroupTemplates) return <Spinner />;
    
    return (
        <PaginatedList
            getKey={m => m._id}
            selectedOnly={selectedOnly}
            hideTabs={hideTabs}
            getTabVisible={t => {
                switch (t) {
                    case "Templates": return !isTemplateEditing;
                    case "ParentGroup": return !!mappingGroup.extendsGroup;
                }

                return true;
            }}
            items={mappingGroup?.mappings ?? []}
            tabs={MappingListTab}
            headers={getHeaders}
            renderItem={m => {

                const isFromParentGroup = getId(m?.group) === getId(mappingGroup?.extendsGroup);

                return [
                    <Flex gap={0}>
                        <Flex row>
                            <Text>{ m.name }</Text>
                            {
                                m.isCalculated && <Badge color="informative" appearance="outline"><Text weight="bold">{getIcon(m.resultType)}</Text></Badge>
                            }
                            
                            {
                                ((m.isTemplate || !!m.baseTemplate) && !isTemplateEditing) && (
                                    <Badge 
                                        color="success" 
                                        appearance={!!m.baseTemplate ? "tint" : "filled"}
                                    >
                                        Vorlage
                                    </Badge>
                                )
                            }
                            <EntityTypeSelect size="small" selectedEntityTypes={m.appliesToEntities} readOnly />
                            {
                                m.resultIsDeltaToLastYear && <Badge color="severe" appearance="tint">Vorjahresvergleich</Badge>
                            }
                        </Flex>
                        {m.shortName && <Text size={200}>({m.shortName})</Text>}
                    </Flex>,
                    (!m.isCalculated || m.resultType === CalculationResultType.Currency) ? <BalanceTypeBadge type={m.indicator} /> : <Text>-</Text>,
                    <MappingPreviewForGroup mapping={m} />,
                    !canSelect && (
                        <Flex row>
                            { 
                                (m.isCalculated && !m.isTemplate && !isTemplateEditing && !isTemplateUsage) && (
                                    <CalculateMappingTest 
                                        mapping={m}
                                        mappingGroup={mappingGroup} 
                                    />
                                ) 
                            }
                            {
                                (isTemplateUsage && m.isCalculated && !!m.baseTemplate) && (
                                    <Tooltip
                                        relationship="description"
                                        content={(
                                            <Flex>
                                                <Text weight="bold">Automatische Übernahme</Text>
                                                <Text>Berechnete Vorlagen können nicht bearbeitet werden. Sie werden automatisch übernommen</Text>
                                            </Flex>
                                        )}
                                        
                                    >
                                        <Badge color="severe" appearance="tint" icon={<InfoRegular />}>Automatisch</Badge>
                                    </Tooltip> 
                                )
                            }
                            {
                                !isFromParentGroup && (
                                    <UpdateMappingForm  
                                        mappingGroup={mappingGroup}
                                        customChangeHandler={customChangeHandler}
                                        afterChange={reloadMappingGroupTemplates}
                                        mapping={m} 
                                        isTemplateEditing={isTemplateEditing}
                                        isTemplateUsage={isTemplateUsage}
                                    />
                                )
                            }
                            {
                                (!isFromParentGroup && (isTemplateEditing || (!m.isTemplate && !m.baseTemplate))) && (
                                    <DeleteMappingButton 
                                        mappingGroup={mappingGroup} 
                                        mapping={m}
                                        afterDelete={reloadMappingGroupTemplates} 
                                    />
                                )
                            }
                            {
                                (getId(m.group) === getId(mappingGroup?.extendsGroup)) && <Badge appearance="tint" color="success">Übernahme aus Mutterkontenrahmen</Badge>
                            }
                        </Flex>
                    )
                ];
            }}
            canSelect={canSelect && !isTemplateUsage}
            selectedKeys={selectedKeys}
            filterPlaceholder="Nach Positionen oder Konten suchen"
            getTabIcon={t => {
                switch (t) {
                    case "Templates": return <ColorRegular />;
                    case "Calculated": return <CalculatorRegular />;
                    case "NotCalculated": return <BuildingBankRegular />;
                    case "ParentGroup": return <ArrowCircleDownSplitRegular />;
                    default: return null;
                }
            }}
            getTabLabel={t => {
                switch (t) {
                    case "Templates": return "Vorlagen";
                    case "Calculated": return "Berechnete Positionen";
                    case "NotCalculated": return "Kontengruppen";
                    case "ParentGroup" : return "Mutter-Kontenrahmen"
                    default: return "Alle";
                }
            }}
            itemMatchesFilter={(filter, m) => {
                if (!m.name) return false;
                
                const usableFilter = filter.toLowerCase();
                const name = m.name.toLowerCase();
    
                const nameMatches = usableFilter.includes(name) || name.includes(usableFilter);
    
                if (nameMatches) return true;
    
                const numericFilter = parseInt(filter);
    
                if (isNaN(numericFilter)) return false;
    
                return m.accounts.some(a => a.accountFrom <= numericFilter && (a.accountTo === undefined || a.accountTo >= numericFilter));
            }}
            itemMatchesTab={(tab, m) => {
                if (tab === "Calculated") return m.isCalculated;
                if (tab === "NotCalculated") return !m.isCalculated;
                if (tab === "Templates") return m.isTemplate || !!m.baseTemplate;
                if (tab === "ParentGroup") return getId(m.group) === getId(mappingGroup?.extendsGroup);
                return true;
            }}
            onRemove={onRemove}
            onSelect={onSelect}
            pageSize={pageSize}
            size={size}
            slot={slot}
            slotPosition={slotPosition}
        />
    )
}
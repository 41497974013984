import { Spinner, Text } from "@fluentui/react-components";
import { AddRegular, EditRegular } from "@fluentui/react-icons";
import { FieldArray, useFormikContext } from "formik";
import React from "react";
import { useMappingGroup } from "../../../state/mappingGroups/useMappingGroup";
import { useMappingTemplates } from "../../../state/mappings/useMappingTemplates";
import { IChartDocument, IChartMapping, IChartMappingGroupBase } from "../../../types/charts.schema";
import { BalanceIndicator, IMapping } from "../../../types/mapping.schema";
import { IMappingGroupWithMappings } from "../../../types/mappingGroup.schema";
import { getId } from "../../../util/mongoUtil";
import Flex from "../container/Flex";
import MappingList from "../mappings/MappingList";
import ModalDialog from "../modal/ModalDialog";
import AddNewMappingGroupButton from "./AddNewMappingGroupButton";
import ChartGroupFieldMapping from "./ChartGroupFieldMapping";
import ChartGroupTabs from "./ChartGroupTabs";

export const TemplateFieldId = "templates";

export default function ChartGroupsField() {

    const [selectedGroup, setSelectedGroup] = React.useState<string>(TemplateFieldId);
    
    const {
        mappingTemplatesById,  
        mappingTemplates, 
        loadingMappingTemplates
    } = useMappingTemplates();

    const {
        loadingMappingGroup,
        mappingGroup
    } = useMappingGroup(selectedGroup);

    const {
        values,
        setFieldValue
    } = useFormikContext<IChartDocument>();

    const getFieldName = (groupIndex: number) => {
        if (selectedGroup === TemplateFieldId) return `templates`;
        return `groups.${groupIndex}`;
    }

    const getAvailableMappingsById = () => {
        if (selectedGroup === TemplateFieldId) return mappingTemplatesById;
        if (!mappingGroup || !mappingGroup.mappingsById) return {};
        return mappingGroup.mappingsById;
    }

    const getSelectedGroupIndex = () => { 
        if (selectedGroup === TemplateFieldId) return -1;
        return (
            values.groups && !!values.groups.length 
            ? values.groups.findIndex(g => getId(g.mappingGroup) === selectedGroup)
            : -1
        );
    }

    const getAvailableMappingGroup = () => {
        if (selectedGroup === TemplateFieldId) return { 
            mappings: mappingTemplates, 
            mappingsById: mappingTemplatesById 
        } as IMappingGroupWithMappings;
        return mappingGroup;
    }

    const getSelectedGroup = (index: number): IChartMappingGroupBase => {
        if (selectedGroup === TemplateFieldId) return values.templates;
        return values.groups?.[index] ?? { mappings: [], yoyStart: undefined };
    }

    const selectedGroupIndex = getSelectedGroupIndex();
    const selectedChartMappingGroup = getSelectedGroup(selectedGroupIndex);
    const selectedIds = selectedChartMappingGroup?.mappings?.map(m => getId(m.mapping)) ?? [];
    const fieldName = getFieldName(selectedGroupIndex);

    const getAvailableSankeyMappings = (sourceMappingId: string): Array<IMapping> => {
        if (!selectedChartMappingGroup || !mappingGroup || !selectedChartMappingGroup.mappings || !selectedChartMappingGroup.mappings.length) return [];

        const mappingsById = getAvailableMappingsById();

        if (!mappingsById) return [];

        const existingMappings = new Set<string>();
        const result: Array<IMapping> = [];

        for (const id of selectedIds) {
            if (existingMappings.has(id)) continue;
            if (id === sourceMappingId) continue;
            const mapping = mappingsById[id];
            if (!mapping) continue;
            existingMappings.add(id);
            result.push(mapping);
        }
        
        return result;
    }

    const availableMappingGroup = getAvailableMappingGroup();

    return (
        <Flex fullWidth>
            <Flex row justify="between" fullWidth>
                <ChartGroupTabs 
                    selectedGroup={selectedGroup} 
                    onSelect={group => setSelectedGroup(group)}
                    onRemove={group => {
                        const newGroups = values.groups.filter(g => getId(g.mappingGroup) !== group);
                        setFieldValue("groups", newGroups);
                    }} 
                />
                <AddNewMappingGroupButton 
                    addGroup={groupId => {
                        setSelectedGroup(groupId);

                        const newGroups = [
                            ...values.groups,
                            {
                                mappingGroup: groupId,
                                mappings: []
                            }
                        ]

                        setFieldValue("groups", newGroups);
                    }}
                />
            </Flex>
            {
                (loadingMappingGroup || loadingMappingTemplates)
                ? <Spinner />
                : (
                    (!!availableMappingGroup) && (
                        <FieldArray name={`${fieldName}.mappings`}>
                            {
                                arrayHelpers => (
                                    <Flex fullWidth>
                                        <Flex fullWidth row justify="between">
                                            <Text>Ausgewählte Kontengruppen</Text>
                                            <ModalDialog
                                                title="Kontengruppen auswählen"
                                                text="Kontengruppen auswählen"
                                                appearance="transparent"
                                                icon={selectedIds.length > 0 ? <EditRegular /> : <AddRegular />}
                                            >
                                                <MappingList 
                                                    mappingGroup={availableMappingGroup} 
                                                    onSelect={m => {
                                                        arrayHelpers.push<IChartMapping>({
                                                            mapping: m,
                                                            useSecondaryType: false,
                                                            adjustments: undefined,
                                                            sankeyData: { sourceMapping: "" },
                                                            yoyPositiveResultIs: BalanceIndicator.Debit
                                                        })

                                                        if (!values.title) setFieldValue("title", m.name);
                                                    }}
                                                    selectedKeys={selectedIds}
                                                    onRemove={m => {
                                                        const index = selectedIds.indexOf(getId(m));
                                                        if (index > -1) arrayHelpers.remove(index);
                                                    }}
                                                    canSelect
                                                />
                                            </ModalDialog>
                                        </Flex>
                                        <Flex fullWidth gap={3}>
                                            {
                                                (selectedChartMappingGroup.mappings && !!selectedChartMappingGroup.mappings.length)
                                                ? selectedChartMappingGroup.mappings.map((m, index) => {
    
                                                    const mappingId = getId(m.mapping);
                                                    const mapping = availableMappingGroup?.mappingsById?.[mappingId];
                                                    const isAdjusted = !!m.adjustments;

                                                    if (!mapping) return null;
    
                                                    return (
                                                        <ChartGroupFieldMapping
                                                            key={index} 
                                                            isAdjusted={isAdjusted} 
                                                            chartMapping={m}
                                                            getAvailableSankeyMappings={getAvailableSankeyMappings}
                                                            mappingGroup={availableMappingGroup}
                                                            chartGroup={selectedChartMappingGroup}
                                                            remove={arrayHelpers.remove}
                                                            fieldNamePrefix={fieldName}
                                                            mapping={mapping} 
                                                            mappingId={mappingId} 
                                                            index={index}
                                                            swap={arrayHelpers.swap}
                                                        />
                                                    )
                                                })
                                                : <Text>Keine Zuweisungen ausgewählt</Text>
                                            }
                                        </Flex>
                                    </Flex>
                                )
                            }
                        </FieldArray>
                    )
                )
            }
         </Flex>
    )
}
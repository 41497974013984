import useSWR from "swr";
import { RouteParam, Routes } from "../../api/apiRoutes";
import { IHydratedMappingGroupWithMappings, IMappingGroup, IMappingGroupWithMappings } from "../../types/mappingGroup.schema";
import { ICollection } from "../../types/collection.types";

export const useMappingGroups = () => {

    const { data, isLoading, mutate } = useSWR<ICollection<IHydratedMappingGroupWithMappings>>(Routes.mappingGroups.all.route);

    return {
        mappingGroups: data?.items ?? [],
        mappingGroupsById: data?.byId ?? {},
        loadingMappingGroups: isLoading,
        reloadMappingGroups: mutate
    }
}
import { Badge, Card, Input, Radio, RadioGroup, Text } from "@fluentui/react-components";
import { AddRegular, SaveRegular } from "@fluentui/react-icons";
import { useFormikContext } from "formik";
import React from "react";
import { CalculationElementValueSource, IMapping, IMappingCalculationElement, MappingCalculationElementType, Operator } from "../../../../types/mapping.schema";
import { IHydratedMappingGroupWithMappings, IMappingGroupWithMappings } from "../../../../types/mappingGroup.schema";
import CustomButton from "../../button/CustomButton";
import Flex from "../../container/Flex";
import ModalDialog from "../../modal/ModalDialog";
import MappingList from "../MappingList";
import CalculationElementBadge from "./CalculationElementBadge";
import CalculatedPositionPreview from "./CalculationElementPreview";
import { IWithTemplateProps } from "../UpdateMappingsForm";

export interface IUpdateCalculationStepFormProps extends IWithTemplateProps {
    element?: IMappingCalculationElement,
    mappingGroup: IMappingGroupWithMappings,
    calculcationStepIndex?: number,
    addNewCalculationStep?: (element: IMappingCalculationElement) => void,
    removeElement?: () => void
}

export default function UpdateCalculationStepForm(props: IUpdateCalculationStepFormProps) {

    const {
        element: baseElement,
        mappingGroup,
        isTemplateEditing,
        isTemplateUsage,
        calculcationStepIndex,
        addNewCalculationStep,
        removeElement
    } = props;

    const {
        setFieldValue
    } = useFormikContext<IMapping>();

    const getEmptyElement = (): IMappingCalculationElement => ({
        type: MappingCalculationElementType.Operator,
        operator: Operator.Add,
        accountFrom: 0,
        accountTo: 0,
        valueSource: isTemplateEditing ?  CalculationElementValueSource.Mappings : CalculationElementValueSource.Accounts,
        mappings: [],
        value: 0
    });

    const [element, setElement] = React.useState<IMappingCalculationElement>(baseElement ?? getEmptyElement());

    const updateFieldValue = (field: keyof IMappingCalculationElement, value: any) => {
        const newValue = {
            ...element,
            [field]: value
        };

        setElement(newValue);
    }

    const saveUpdates = () => {
        if (calculcationStepIndex === undefined) {
            addNewCalculationStep?.(element);
            setElement(getEmptyElement());
        }
        else setFieldValue(`calculationSteps.${calculcationStepIndex}`, element);
    }

    const title = (
        calculcationStepIndex === undefined
        ? "Berechnungsschritt hinzufügen"
        : `Berechnungsschritt ${isTemplateEditing ? "ansehen" : "bearbeiten"}`
    );

    const getTrigger = (onClick: () => void) => (
        calculcationStepIndex === undefined
        ? <CustomButton onClick={onClick} appearance="outline" text="Neu" icon={<AddRegular />} />
        : (
            <CalculatedPositionPreview 
                element={element} 
                index={calculcationStepIndex} 
                showActions 
                removeElement={removeElement} 
                editElement={onClick} 
                isTemplateUsage={isTemplateUsage}
            />
        )
    );

    const getOperatorBadge = (o: Operator) => {
        const isActive = o === element.operator;

        return (
            <CalculationElementBadge
                key={o}
                element={{ ...getEmptyElement(), type: MappingCalculationElementType.Operator, operator: o }}
                isActive={isActive}
                onClick={() => updateFieldValue("operator", o)}
            />
        )
    }

    const getSize = () => {
        if (element.type === MappingCalculationElementType.Operator) return "small";
        if (element.valueSource !== CalculationElementValueSource.FixedValue) return "large";
        return "medium";
    }

    const getPriority = () => {
        if (element.type === MappingCalculationElementType.Operator) return 1;
        if (element.valueSource !== CalculationElementValueSource.FixedValue ) return 10;
        return 1;
    }

    return (
        <ModalDialog
            size={getSize()}
            priority={getPriority()}
            title={title}
            button={getTrigger}
            abortButtonText={isTemplateUsage ? "Schließen" : "Abbrechen"}
            action={isTemplateUsage ? undefined : close => <CustomButton onClick={() => { saveUpdates(); close(); }} text="Speichern" icon={<SaveRegular />} />}
        >
            <Flex fullWidth>
                {
                    !isTemplateUsage && (
                        <Card className="w-100" appearance="outline">
                            <Flex gap={1}>
                                <Flex fullWidth row gap={1}>
                                    <Text>Typ</Text>
                                    <RadioGroup 
                                        key="calculation-step-type"
                                        layout="horizontal"
                                        onChange={(_, data) => {
                                            const newType = data.value as MappingCalculationElementType;

                                            if (newType === MappingCalculationElementType.Operator) {
                                                updateFieldValue("mappings", []);
                                                updateFieldValue("accountFrom", 0);
                                                updateFieldValue("accountTo", 0);
                                                updateFieldValue("value", 0);
                                            }

                                            updateFieldValue("type", newType)
                                        }}
                                        value={element.type}
                                        name="type"
                                    >
                                        <Radio value={MappingCalculationElementType.Operator} label="Operator" />
                                        <Radio value={MappingCalculationElementType.Operand} label="Operand" />
                                    </RadioGroup>
                                </Flex>
                                {
                                    element.type !== MappingCalculationElementType.Operator && (
                                        <Flex fullWidth row gap={1}>
                                            <Text>Wertetyp</Text>
                                            <RadioGroup 
                                                key="calculation-step-value-source"
                                                layout="horizontal"
                                                onChange={(_, data) => {
                                                    const newType = data.value as CalculationElementValueSource;

                                                    switch (newType) {
                                                        case CalculationElementValueSource.Accounts:
                                                        case CalculationElementValueSource.FixedValue:

                                                            updateFieldValue("mappings", []);
                                                            break;

                                                    }

                                                    updateFieldValue("valueSource", newType)
                                                }}
                                                value={element.valueSource ?? (isTemplateEditing ? CalculationElementValueSource.Mappings : CalculationElementValueSource.Accounts)}
                                                name="valueSource"
                                            >
                                                {
                                                    !isTemplateEditing && <Radio value={CalculationElementValueSource.Accounts} label="Kontenspanne" />
                                                }
                                                <Radio value={CalculationElementValueSource.Mappings} label="Kontengruppen" />
                                                <Radio value={CalculationElementValueSource.FixedValue} label="Fixer Wert" />
                                            </RadioGroup>
                                        </Flex>
                                    )
                                }
                            </Flex>
                            {
                                element.type === MappingCalculationElementType.Operator && (
                                    <Flex fullWidth>
                                        <Text>Operator auswählen</Text>
                                        <Flex row wrap>
                                            { getOperatorBadge(Operator.Add)}
                                            { getOperatorBadge(Operator.Subtract)}
                                            { getOperatorBadge(Operator.Divide)}
                                            { getOperatorBadge(Operator.Multiply)}
                                            { getOperatorBadge(Operator.LeftParenthesis)}
                                            { getOperatorBadge(Operator.RightParenthesis)}
                                        </Flex>
                                    </Flex>
                                )
                            }
                        </Card>
                    )
                }
                {
                    element.type !== MappingCalculationElementType.Operator && (
                        <CalculationElementOperandValue 
                            element={element}
                            isTemplateEditing={isTemplateEditing}
                            isTemplateUsage={isTemplateUsage}
                            updateFieldValue={updateFieldValue}
                            mappingGroup={mappingGroup}
                        />
                    )
                }
            </Flex>
        </ModalDialog>
    )
}

export interface ICalculationElementOperandValueProps extends IWithTemplateProps {
    element: IMappingCalculationElement,
    mappingGroup: IHydratedMappingGroupWithMappings,
    updateFieldValue: (key: keyof IMappingCalculationElement, value: any) => void,
}

function CalculationElementOperandValue(props: ICalculationElementOperandValueProps) {
    const {
        element,
        isTemplateEditing,
        isTemplateUsage,
        updateFieldValue,
        mappingGroup
    } = props;

    const getMappingForId = (id: string) => {
        const quickResult = (mappingGroup && mappingGroup.mappingsById) ? mappingGroup.mappingsById[id] : undefined;
        if (quickResult) return quickResult;
        return mappingGroup?.mappings?.find(m => m._id === id);
    }
    const getContent = () => {
        switch (element.valueSource) {
            case CalculationElementValueSource.Accounts:

                if (isTemplateEditing) return null;

                return (
                    <Flex fullWidth>
                        <Flex gap={0}>
                            <Text>Positionen über Kontennummern saldieren</Text>
                            <Text size={200}>Sämtliche Positionen innerhalb der Kontenspanne werden entsprechend ihrem Bilanztyp (oder Ergebnis bei berechneten Positionen) saldiert.</Text>
                        </Flex>
                        <Flex row fullWidth>
                            <Flex fullWidth gap={0}>
                                <Text>Von</Text>
                                <Input 
                                    appearance="filled-darker"
                                    value={`${element.accountFrom}`}
                                    type="number"
                                    className="w-100"
                                    onChange={(e, val) => updateFieldValue("accountFrom", val.value)}
                                    placeholder="Von"
                                />
                            </Flex>
                            <Flex fullWidth gap={0}>
                                <Text>Bis</Text>
                                <Input 
                                    appearance="filled-darker"
                                    value={`${element.accountTo}`}
                                    type="number"
                                    className="w-100"
                                    onChange={(e, val) => updateFieldValue("accountTo", val.value)}
                                    placeholder="Von"
                                />
                            </Flex>
                        </Flex>
                    </Flex>
                );

            case CalculationElementValueSource.Mappings:

            console.log(mappingGroup);
                
                return (
                    <>
                        <Flex fullWidth gap={0}>
                            <Text>Saldierte Positionen</Text>
                            <Text size={200}>Die ausgewählten Positionen werden entsprechend ihrem Bilanztyp saldiert.</Text>
                        </Flex>
                        {
                            mappingGroup?.mappings?.length
                            ? (
                                <>
                                    {
                                        element.mappings && !!element.mappings.length && (
                                            <Flex row wrap fullWidth>
                                                {
                                                    element.mappings.map(m => {
                                                        if (!m) return null;
                                                        const name = getMappingForId(m)?.name ?? "";
                                                        if (!name) return null;
                                                        return <Badge key={m} appearance="filled" >{name}</Badge>
                                                    })
                                                }
                                            </Flex>
                                        )
                                    }
                                    <MappingList 
                                        size="small"
                                        mappingGroup={mappingGroup}
                                        isTemplateEditing={isTemplateEditing}
                                        isTemplateUsage={isTemplateUsage}
                                        canSelect
                                        pageSize={10}
                                        selectedOnly={isTemplateUsage}
                                        onSelect={(m: IMapping) => {
                                            if (element.mappings.includes(m._id)) return;
                                            const newMappings = [...element.mappings, m._id];
                                            updateFieldValue("mappings", newMappings)
                                        }}
                                        onRemove={(m: IMapping) => {
                                            const newMappings = element.mappings.filter(id => id !== m._id);
                                            updateFieldValue("mappings", newMappings)
                                        }}
                                        selectedKeys={element.mappings}
                                    />
                                </>

                            )
                            : <Text>Für diesen Kontenrahmen sind keine Kontengruppen hinterlegt</Text>
                        }
                    </>
                )
                
            case CalculationElementValueSource.FixedValue:

                return (
                    <Flex fullWidth>
                        <Text>Wert</Text>
                        <Input 
                            appearance="filled-darker"
                            value={`${element.value}`}
                            type="number"
                            className="w-100"
                            onChange={(e, val) => updateFieldValue("value", Number(val.value))}
                            placeholder="Wert"
                        />
                    </Flex>
                )
        }

    }

    return (
        <Card className="w-100" appearance="outline">
            {getContent()}
        </Card>
    )
}
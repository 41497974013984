import React, { PropsWithChildren, ReactNode } from "react";
import { Formik, Form, FormikBag, FormikHelpers, FormikProps, FormikValues } from "formik";
import { Button, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, DialogTrigger, Spinner } from "@fluentui/react-components";
import CustomButton from "../button/CustomButton";
import { SaveRegular } from "@fluentui/react-icons";
import Flex from "../container/Flex";
import { IModalPropsBase } from "./ModalForm";

interface IModalDialogProps extends IModalPropsBase {
    className?: string,
    disabled?: boolean,
    abortButtonText?: string,
    action?: (close: () => void) => React.ReactElement,
    minWidth?: number,
    children: ReactNode
}

export default function ModalDialog(props: IModalDialogProps) {
    
    const { 
        disabled, 
        renderChildrenOnlyWhenVisible,
        appearance = "subtle",
        minWidth,
        abortButtonText,
        action,
        title, 
        loading,
        priority = 5,
        size = "medium",
        button,
        children, 
        className,
        text,
        icon
    } = props;

    const [visible, setVisible] = React.useState<boolean>(false);

    const close = () => setVisible(false);

    const getMinWidth = () => {
        const sizeMap = {
            small: 400,
            medium: 600,
            large: 900
        }

        const priorityAddition = 50;
        return sizeMap[size] + priority * priorityAddition;
    }

    return (
        <Dialog 
            onOpenChange={(val, data)=> setVisible(data.open)} 
            open={visible}
        >
            { button ? button(() => setVisible(true)) : <CustomButton onClick={() => setVisible(true)} disabled={disabled}  appearance={appearance} className={className} icon={icon} loading={loading} text={text} /> }
            <DialogSurface style={{width: getMinWidth() + "px", maxWidth: "calc(100vw - 25px)", display: renderChildrenOnlyWhenVisible && !visible ? "none" : undefined}}>
                {
                    (visible || !renderChildrenOnlyWhenVisible) && (
                        <DialogBody>
                            <DialogTitle>{title}</DialogTitle>
                            <DialogContent>
                                <Flex fullWidth>
                                    {
                                        children
                                    }
                                </Flex>
                            </DialogContent>
                            <DialogActions>
                                    <Flex fullWidth row justify="between">
                                        {
                                            !!action && <Button type="button" onClick={close}>{abortButtonText ?? "Abbrechen"}</Button>
                                        }
                                        {
                                            action ? action(() => setVisible(false)) : <Button type="button" onClick={close}>Schließen</Button>
                                        }
                                    </Flex>
                            </DialogActions>
                        </DialogBody>
                    )
                }
            </DialogSurface>
        </Dialog>
    );
}
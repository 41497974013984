import { ContextExclusionPlugin } from "webpack";
import useApi from "../api/useApi";
import { useCharts } from "../state/charts/useCharts";
import { ModalType } from "../state/modal/modal.state";
import { IUploadableFile } from "../types/googlecloudstorage.types";
import { ISlideDeck } from "../types/slideDeck.schema";
import { getId } from "../util/mongoUtil";
import useChartUtil from "./useChartUtil";
import useDynamicChartImage from "./useDynamicChartImage";
import useNotifications from "./useModal";
import usePersistance, { StorageKey } from "./usePersistance";

export default function useSlideDeckPreset(slideDeck: ISlideDeck) {
    
    const {
        value: currentEntityType     
    } = usePersistance<string>(StorageKey.EntityType);

    const {
        getChartById
    } = useChartUtil();

    const {
        pasteChartImageToActiveSlide
    } = useDynamicChartImage();

    const {
        slideDecksDownload
    } = useApi();

    const {
        showNotification
    } = useNotifications();

    const downloadFile = async () => await slideDecksDownload(slideDeck._id);
    
    const addPresetFileSlidesToPresentation = async (file: IUploadableFile, append: boolean): Promise<boolean> => {
        if (!file || !file.content) return false;

        const base64 = file.content;

        if (!base64) return false;
        if (!base64.includes("base64")) return false;

        const contentArray = base64.split("base64");

        if (contentArray.length < 2) return false;

        const presentationContent = contentArray[1];

        if (!presentationContent) return false;

        await PowerPoint.run(async (context) => {
            if (!append) {
                const slideCount = context.presentation.slides.getCount();
                await context.sync();

                for (let i = 0; i < slideCount.value; i++) {
                    const slide = context.presentation.slides.getItemAt(0);
                    await context.sync();
                    slide.delete();
                    await context.sync();	
                }
            }

            context.presentation.insertSlidesFromBase64(presentationContent, { formatting: "KeepSourceFormatting" });
            await context.sync();
        });
        
        return true;
    }

    const setSlideTo = async (index: number = -1): Promise<string> => await PowerPoint.run(async (ctx) => {
        const slideCount = ctx.presentation.slides.getCount();
        await ctx.sync();
        const usedIndex = index >= 0 ? index : slideCount.value - 1;
        
        if (usedIndex >= slideCount.value) {
            for (let i = slideCount.value; i <= usedIndex; i++) await addSlide();
        }

        const slide = ctx.presentation.slides.getItemAt(index);
        await ctx.sync();
        ctx.presentation.setSelectedSlides([ slide.id ]);
        await ctx.sync();
        return slide.id;
    });

    const addSlide = async (): Promise<boolean> => await PowerPoint.run(async (ctx) => {
        ctx.presentation.slides.add();
        await ctx.sync();
        return true;
    });

    const applySlideDeckCharts = async (append: boolean): Promise<boolean> => {
        try {
            let slideIndex = -1;

            for (const slide of slideDeck.slides) {

                if (currentEntityType && !!slide.visibleForEntities && !!slide.visibleForEntities.length && !slide.visibleForEntities.includes(currentEntityType)) continue;
                
                if (slide.index >= 0) {
                    // slide index is saved as display index (1-based)
                    slideIndex = slide.index - 1;
                }
                else slideIndex++;

                console.log("SLIDE INDEX: ", slideIndex);

                if (append) await addSlide();
                
                await setSlideTo(append ? -1 : slideIndex);

                for (const slideChart of slide.charts) {
                    const chart = getChartById(slideChart.chart);

                    if (!chart) continue;

                    await pasteChartImageToActiveSlide(chart, {
                        position: slideChart.position,
                        size: slideChart.size,
                        padding: slideDeck.padding
                    });
                }
            }

            return true;
        }
        catch (e) { 
            showNotification({
                text: "Fehler beim Anwenden der Präsentation",
                type: ModalType.Error
            });

            console.log(e);
            return false; 
        }
    }

    return {
        downloadFile,
        applySlideDeckCharts,
        applyPresetFileSlides: addPresetFileSlidesToPresentation
    }

}
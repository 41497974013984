import { Badge, Button, Card, Checkbox, Input, Menu, MenuItem, MenuList, MenuPopover, MenuTrigger, Spinner, Switch, Tab, TabList, Text, Tooltip } from "@fluentui/react-components";
import { EyeRegular, FilterRegular, SearchRegular } from "@fluentui/react-icons";
import React from "react";
import useChartUtil from "../../../hooks/useChartUtil";
import usePersistance, { StorageKey } from "../../../hooks/usePersistance";
import useYearUtil from "../../../hooks/useYearUtil";
import { useCharts } from "../../../state/charts/useCharts";
import { useMappingGroups } from "../../../state/mappingGroups/useMappingGroups";
import { ChartType, IChart } from "../../../types/charts.schema";
import { getId } from "../../../util/mongoUtil";
import Flex from "../container/Flex";
import Grid from "../container/Grid";
import PaginatedList from "../list/PaginatedList";
import SelectNumberGroup from "../mappings/groups/MappingGroupSelect";
import ColorModeSwitch from "../palette/ColorModeSwitch";
import ChartGroupByBanner from "./ChartGroupByBanner";
import ChartItem from "./ChartItem";
import "./ChartList.css";
import ChartOrientationBanner from "./ChartOrientationBanner";
import ChartPresetBanner from "./ChartPresetBanner";
import ChartYoyBadge from "./ChartYoyBadge";
import DeleteChartButton from "./DeleteChartButton";
import UpdateChartForm from "./UpdateChartForm";

export interface IChartListProps {
    canSelect?: boolean,
    onSelect?: (chart: IChart) => void,
    selectedItem?: string
}

export enum ChartTabValue {
    All = "all",
    Mine = "mine",
    Global = "global"
}

export default function ChartList(props: IChartListProps) {
    
    const {
        loadingMappingGroups,
        mappingGroups
    } = useMappingGroups();

    const {
        value: currentGroupId
    } = usePersistance<string>(StorageKey.SelectedMappingGroup);

    const {
        getLabelForYear
    } = useYearUtil();

    const {
        canSelect = false,
        onSelect,
        selectedItem
    } = props;

    const [onlyCurrentGroup, setOnlyCurrentGroup] = React.useState<boolean>(true);
    const [tab, setTab] = React.useState<ChartTabValue>(ChartTabValue.All);
    const [title, setTitle] = React.useState<string>();
    const [hiddenTypes, setHiddenTypes] = React.useState<Array<ChartType>>();
    const [showList, setShowList] = React.useState<boolean>(false);

    const {
        getLabelForChartType
    } = useChartUtil();

    const {
        loadingCharts,
        charts
    } = useCharts();

    const filterCharts = () => {
        if (!charts || !charts.length) return [];

        const items = charts.filter(c => {
            if (onlyCurrentGroup) {
                if (c.groups && c.groups.length && c.groups.some(g => getId(g.mappingGroup) === currentGroupId)) return true;
                if (!!c.templates) return true;
                return false;
            } 
            
            if (!!title && !c.title.toLowerCase().includes(title.toLowerCase())) return false;
            if (!!hiddenTypes && !!hiddenTypes.length && hiddenTypes.includes(c.type)) return false;
            if (!!tab) {
                switch (tab) {
                    case ChartTabValue.All: return true;
                    case ChartTabValue.Mine: return !c.isGlobal;
                    case ChartTabValue.Global: return c.isGlobal;
                }
            }
            return true;
        });

        return items;
    }

    const filteredCharts = filterCharts();

    if (!currentGroupId) return (
        <Flex fullWidth>
            {
                (loadingMappingGroups || (mappingGroups && !!mappingGroups.length))
                ? (
                    <>
                        <Text>Bitte wählen Sie eine Zuordnungsgruppe aus, um Diagramme anzuzeigen.</Text>
                        <SelectNumberGroup />
                    </>
                )
                : <Text>Bitte erstellen Sie zuerst eine Zuordnungsgruppe, um Diagramme anzuzeigen.</Text>
            }
        </Flex>
    )

    const contentFilter = (
        <Menu hasIcons>
            <MenuTrigger disableButtonEnhancement>
                <Button
                    appearance="transparent"
                    icon={<FilterRegular />}
                />
            </MenuTrigger>
            <MenuPopover>
                <MenuList>
                    {
                        Object.keys(ChartType).map((key: any) => {

                            const isSelected = !hiddenTypes || !hiddenTypes.length || !hiddenTypes.includes(ChartType[key]);
                            const type = ChartType[key];
                            const label = getLabelForChartType(type, false);
                            return (
                                <MenuItem
                                    persistOnClick
                                    key={type}
                                    icon={
                                        isSelected
                                        ? <EyeRegular />
                                        : undefined
                                    }
                                    onClick={() => {
                                        if (!hiddenTypes) {
                                            setHiddenTypes([type]);
                                        } else {
                                            if (hiddenTypes.includes(type)) {
                                                setHiddenTypes(hiddenTypes.filter(t => t !== type));
                                            } else {
                                                setHiddenTypes([...hiddenTypes, type]);
                                            }
                                        }
                                    }}
                                >
                                    {label}
                                </MenuItem>
                            )
                        })
                    }
                </MenuList>
            </MenuPopover>
        </Menu>
    )

    
    const searchBox = (
        <Flex row wrap>
            <ColorModeSwitch />
            {
                contentFilter
            }
            <Input 
                value={title} 
                onChange={(e, data) => setTitle(data.value)} 
                placeholder="Suchen..." 
                type="text" 
                contentAfter={<SearchRegular />}
            />
        </Flex>
    );

    return (
        <Flex fullWidth fullHeight wrap>
            <Flex fullWidth row justify="between" wrap>
                <Text weight="bold">Gespeicherte Charts</Text>
                <Flex row>
                    <Tooltip 
                        content="Diagramme, welche keine Kontengruppen für den aktuellen Kontenrahmen hinterlegt haben, werden ausgeblendet."
                        relationship="description"
                    >
                        <Checkbox
                            label="Nur für aktuellen Kontenrahmen anzeigen"
                            checked={onlyCurrentGroup}
                            onChange={(_, data) => setOnlyCurrentGroup(!!data.checked)}
                        />
                    </Tooltip>
                    <Switch
                        onChange={(_, val) => setShowList(val.checked)}
                        label="Als Liste zeigen"
                        labelPosition="before"
                    />
                    {
                        canSelect
                        ? searchBox
                        : <UpdateChartForm />
                    }
                </Flex>
            </Flex>
            <Flex fullWidth fullHeight style={{minHeight: "100px"}}>
                {
                    loadingCharts
                    ? <Spinner />
                    : (
                        (!!charts && !!charts.length) 
                        ? (
                            <Flex fullWidth>
                                <Flex fullWidth gap={0}>
                                    <Flex row wrap justify="between" fullWidth>
                                        <TabList
                                            size="small"
                                            appearance="subtle"
                                            onTabSelect={(_, data) => setTab(data.value as ChartTabValue)}
                                            selectedValue={tab}
                                        >
                                            <Tab value={ChartTabValue.All}>Alle</Tab>
                                            <Tab value={ChartTabValue.Mine}>Meine</Tab>
                                            <Tab value={ChartTabValue.Global}>Vorlagen</Tab>
                                        </TabList>
                                        {
                                            !canSelect && searchBox
                                        }
                                    </Flex>
                                </Flex>
                                {
                                    filteredCharts && !!filteredCharts.length
                                    ? (
                                        showList
                                        ? (
                                            <Card className="w-100">
                                                <PaginatedList
                                                    getKey={(c: IChart) => c._id}
                                                    headers={[ "Name", "Details", "Aktionen"]}
                                                    items={filteredCharts}
                                                    onSelect={onSelect}
                                                    selectedKeys={canSelect ? [selectedItem] : []}
                                                    pageSize={25}
                                                    canSelect={canSelect}
                                                    renderItem={c => [
                                                        <Flex gap={0}>
                                                            <Text weight="bold">{c.title}</Text>
                                                            <Text>{getLabelForChartType(c.type, false)}</Text>
                                                        </Flex>,
                                                        <Flex gap={1}>
                                                            <Flex row>
                                                                <ChartPresetBanner chart={c} />
                                                                <ChartOrientationBanner chart={c} />
                                                                <ChartGroupByBanner chart={c} />
                                                                <ChartYoyBadge chart={c} />
                                                            </Flex>
                                                            <Flex row wrap>
                                                                {
                                                                    c.years && c.years.map(y => <Badge key={y} color="informative" size="small">{getLabelForYear(y)}</Badge>)
                                                                }
                                                            </Flex>
                                                        </Flex>,
                                                        <Flex row>
                                                            <UpdateChartForm chart={c} />
                                                            <UpdateChartForm chart={c} isDuplication />
                                                            <DeleteChartButton chart={c} />
                                                        </Flex>
                                                    ]}
                                                />
                                            </Card>
                                        )
                                        : (
                                            <Grid cols={canSelect ? 1 : 3}>
                                                {
                                                    filteredCharts.map(c => (
                                                        <ChartItem 
                                                            isSelected={canSelect && c._id === selectedItem}
                                                            onSelect={onSelect}
                                                            isInsertable={!canSelect}
                                                            isSelectable={canSelect}
                                                            isDeletable={!canSelect}
                                                            isEditable={!canSelect}
                                                            key={c._id} 
                                                            chart={c} 
                                                        />
                                                    ))
                                                }
                                            </Grid>
                                        )
                                    )
                                    : <Text>Keine Charts gefunden</Text>
                                }
                            </Flex>
                        )
                        : <Text>Keine Charts vorhanden</Text>
                    )
                }
            </Flex>
        </Flex>
    )
}
import useSWR from "swr";
import { Routes } from "../../api/apiRoutes";
import { ICollection } from "../../types/collection.types";
import { IEntityType } from "../../types/entityType.schema";

export const useEntityTypes = () => {

    const { data, isLoading, mutate } = useSWR<ICollection<IEntityType>>(Routes.entityTypes.all);

    return {
        entityTypes: data?.items ?? [],
        entityTypesById: data?.byId ?? {},
        loadingEntityTypes: isLoading,
        reloadEntityTypes: mutate
    }
}
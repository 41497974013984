import React from "react";
import Flex from "../../container/Flex";
import MappingList, { IMappingListPropsBase } from "../MappingList";
import { useMappingTemplates } from "../../../../state/mappings/useMappingTemplates";
import { Card, Spinner } from "@fluentui/react-components";
import { IMappingGroupWithMappings } from "../../../../types/mappingGroup.schema";

export default function MappingTemplatesList(props: IMappingListPropsBase) {

    const {
        loadingMappingTemplates,
        mappingTemplates
    } = useMappingTemplates();

    if (loadingMappingTemplates) return <Spinner />;

    return (
        <Card className="w-100">
            <MappingList 
                {...props}
                mappingGroup={{
                    mappings: mappingTemplates || []
                } as IMappingGroupWithMappings}
                isTemplateEditing
            />
        </Card>
    )
}
import { Badge } from "@fluentui/react-components"
import { ChevronUpRegular } from "@fluentui/react-icons"
import React from "react"
import Flex from "../container/Flex"
 
export default function ScrollTopButton() {
    return (
        <Badge 
            style={{position: "fixed", bottom: "24px", right: "24px", zIndex: 9999}} 
            onClick={() => document.getElementById("app-content")?.scrollTo({ top: 0, behavior: "smooth"})} 
            size="extra-large"
            color="informative" 
            icon={<ChevronUpRegular />} 
        />
    )
}
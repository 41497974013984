import React from "react";
import { ColorMode } from "../state/color/color.state";
import { useCustomer } from "../state/customers/useCustomer";
import { hexToComplimentary, hexToRgb, rgbToHex } from "../util/color";
import useColorMode from "./useColorMode";
import usePersistance, { StorageKey } from "./usePersistance";

export interface IColorPalette {
    primaries: Array<string>,
    accents: Array<string>,
    complementaries: Array<string>,
}

type RgbKey = "r" | "g" | "b";

export default function useColorPalette() {

    const {
        customer
    } = useCustomer();

    const {
        colorMode
    } = useColorMode();

    const { 
        value: storedPalette,
        updatePersistedValue
    } = usePersistance<IColorPalette>(StorageKey.Palette, true);

    const paletteColors = 20;

    const sourceColors = customer?.settings?.theme || { primary: "#5c1eb0", secondary: "#f5f5f5" };
    const complementary = hexToComplimentary(sourceColors.primary);

    const [palette, setPalette] = React.useState<IColorPalette>();

    const createDefaultPalette = () => {

        const primaries = generateColors(sourceColors.primary, paletteColors);
        const accents = generateColors(sourceColors.secondary, paletteColors);
        const complementaries = generateColors(complementary, paletteColors);

        const colors = {
            primaries,
            accents,
            complementaries
        }

        return colors;
    }

    React.useEffect(() => {
        if (!storedPalette) {
            updatePalette(createDefaultPalette());
            return;
        }
        else setPalette(storedPalette);
    }, []);

    const generateColors = (base: string, count: number = paletteColors): Array<string> => {
        const result = [ base ];

        for (let i = 0; i < count; i++) {
            result.push(generateColorBasedOnSource(base, i));
        }

        return result;
    }

    const generateColorBasedOnSource = (sourceColor: string, offsetToStart: number = -1): string => {

        const source = hexToRgb(sourceColor);

        const getStrongestColorFromSource = (): RgbKey => {
            const strongest = Math.max(source.r, source.g, source.b);
            if (strongest === source.r) return "r";
            if (strongest === source.g) return "g";
            return "b";
        }

        const strongestColor = getStrongestColorFromSource();

        const getAdjustmentMagnitude = (isAdjustingStrongestColor: boolean) => {
            if (offsetToStart < 0) return isAdjustingStrongestColor ? 80 : 20;
            const perStep = 40 / paletteColors;

            const step = offsetToStart * perStep;

            return isAdjustingStrongestColor ? 80 - step : 40 - step;
        }

        const getAdjustment = (isAdjustingStrongestColor: boolean = false) => {
            const value = getAdjustmentMagnitude(isAdjustingStrongestColor);
            const isNegative = Math.random() > 0.5;
            return (isNegative ? -1 : 1) * ((Math.random() + 0.5) * value);
        }

        const getAdjustedValue = (key: RgbKey) => {
            const value = source[key];
            return Math.floor(value + getAdjustment(key !== strongestColor));
        }

        const r = getAdjustedValue("r");
        const g = getAdjustedValue("g");
        const b = getAdjustedValue("b");
        
        return rgbToHex(r, g, b);
    }

    const getPaletteColor = (index: number, totalCount: number = 0): string => {
        const moreDiversityThreshold = 2;
        
        let mode = colorMode;
        
        if (totalCount > moreDiversityThreshold) {
        
            const usedMode = index % 3;
        
            switch (usedMode) { 
                case 0: mode = ColorMode.Primary; break; 
                case 1: mode = ColorMode.Accent; break; 
                case 2: mode = ColorMode.Complementary; break; 
            }
        }


        try {
            const usedPalette = palette ?? createDefaultPalette();

            switch (mode) {
                case ColorMode.Primary: return usedPalette.primaries[index];
                case ColorMode.Accent: return usedPalette.accents[index];
                case ColorMode.Complementary: return usedPalette.complementaries[index];
            }
        }
        catch { }

        switch (mode) {
            case ColorMode.Primary: return generateColorBasedOnSource(sourceColors.primary);
            case ColorMode.Accent: return generateColorBasedOnSource(sourceColors.secondary);
            case ColorMode.Complementary: return generateColorBasedOnSource(complementary);
        }
    }

    const updatePalette = (palette: IColorPalette) => {
        setPalette(palette);
        updatePersistedValue(palette);
    }

    return {
        palette,
        getPaletteColor,
        createNewPalette: createDefaultPalette,
        generateColors,
        getColor: generateColorBasedOnSource,
        getRandomColor: () => generateColorBasedOnSource(sourceColors.primary),
        updatePalette
    }
}
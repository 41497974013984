import React from "react";
import Page from "../components/container/Page";
import { AppRoutes } from "../components/menu/Sidebar";
import DatevNumbersUpload from "../components/datev/DatevNumbersUpload";
import Flex from "../components/container/Flex";
import { Card, Text } from "@fluentui/react-components";
import UpdateChartForm from "../components/charts/UpdateChartForm";
import ChartList from "../components/charts/ChartList";
import useResponsive, { Breakpoint } from "../../hooks/useResponsive";
import SelectNumberGroup from "../components/mappings/groups/MappingGroupSelect";

export default function Charts() {

    const {
        isNarrower
    } = useResponsive(Breakpoint.Mobile);

    return (
        <Page title={AppRoutes.Charts.label} action={isNarrower && <SelectNumberGroup />}>
            <ChartList />
        </Page>
    )
}
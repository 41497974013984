import { ArrowUploadRegular } from "@fluentui/react-icons";
import React from "react";
import useCachedImport from "../../../hooks/useCachedImport";
import useNumberImport, { ExternalDataSource } from "../../../hooks/useNumberImport";
import { replaceImportedNumbers } from "../../../state/datev/numbersImport.state";
import { useAppDispatch, useAppSelector } from "../../../state/reduxHooks";
import IElementProps from "../../../types/element.types";
import { Year } from "../../../types/mapping.schema";
import Flex from "../container/Flex";
import CheckBox from "../formik/CheckBox";
import FormikField from "../formik/FormikField";
import ModalForm from "../modal/ModalForm";
import DatevSingleYearDataForm from "./DatevSingleYearDataForm";
import usePersistance, { StorageKey } from "../../../hooks/usePersistance";

export default function UpdateDatevNumbersForm({ className } : IElementProps) {

    const dispatch = useAppDispatch();

    const state = useAppSelector(state => state.numbersImport);

    const {
        value: currentGroupId
    } = usePersistance<string>(StorageKey.SelectedMappingGroup);

    const {
        calculateMappedNumbers
    } = useNumberImport(ExternalDataSource.Datev, currentGroupId);

    const {
        cacheImport
    } = useCachedImport();
    
    return (
        <ModalForm
            title="Zahlen importieren"
            submitText="Import starten"
            text="Zahlen importieren"
            className={className}
            appearance="primary"
            icon={<ArrowUploadRegular />}
            initialValues={state}
            onSubmit={async (values) => await new Promise(async (resolve) => {
                setTimeout(async () => {
                    values.mappedResults = await calculateMappedNumbers(values.rawData);
                    dispatch(replaceImportedNumbers(values));
                    if (values.cacheLocally) await cacheImport(values);
                    resolve(true);
                }, 1);
            })}
        >
            {
                formik => (
                    <Flex gap={3} fullWidth>
                        <FormikField
                            label="Jahr für Import"
                            name="currentYear"
                            type="number"
                            inline
                        />
                        <DatevSingleYearDataForm year={Year.Current} currentGroupId={currentGroupId} />
                        <DatevSingleYearDataForm year={Year.First} currentGroupId={currentGroupId}/>
                        <DatevSingleYearDataForm year={Year.Second} currentGroupId={currentGroupId}/>
                        <DatevSingleYearDataForm year={Year.Third} currentGroupId={currentGroupId}/>
                        <DatevSingleYearDataForm year={Year.Fourth} currentGroupId={currentGroupId}/>
                        <CheckBox name="cacheLocally" label="Daten lokal zwischenspeichern" />
                    </Flex>
                )
            }
        </ModalForm>
    )
}
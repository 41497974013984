import { Card, Text, Tooltip } from "@fluentui/react-components";
import React from "react";
import { matchPath, useLocation, useNavigate } from "react-router-dom";
import { generateStyle } from "../../../hooks/useAttributes";
import useOfficeVersion from "../../../hooks/useOfficeVersion";
import { useUser } from "../../../state/user/useUser";
import { hexWithOpacity } from "../../../util/color";
import Flex from "../container/Flex";
import { IAppRoute } from "./Sidebar";
import "./SidebarLink.css";

export interface ISidebarLinkProps {
    to: IAppRoute
}

export default function SidebarLink({ to }: ISidebarLinkProps) {

    const [hover, setHover] = React.useState<boolean>(false);

    const {
        customerAdminOnly,
        globalAdminOnly,
    } = to;

    const {
        user
    } = useUser();
    
    const {
        isOpenOutsideOfOffice
    } = useOfficeVersion();

    const location = useLocation();
    const navigate = useNavigate();

    const active = !!matchPath(location.pathname, to.path);

    if (!to.visibleInOffice && !isOpenOutsideOfOffice) return null;
    if (to.hiddenOnTheWeb && isOpenOutsideOfOffice) return null;
    if (customerAdminOnly && !user?.isCustomerAdmin) return null;
    if (globalAdminOnly && !user?.isGlobalAdmin) return null;

    const linkColor = active ? "var(--colorBrandBackground)" : undefined;
    const iconColor = active ? "var(--colorBrandBackground2)" : undefined;

    const clickHandler = () => {
        if (to.external) {
            Office.context.ui.openBrowserWindow(to.path);
        }
        else navigate(to.path);
    }

    return (
        <Tooltip relationship="description" content={to.description ?? to.label}>
            <Card 
                className="p-2 sidebar-link"
                onClick={clickHandler}
                style={generateStyle({
                    name: "outline",
                    value: `1px solid ${linkColor}`,
                    applyCondition: active
                }, {
                    name: "backgroundColor",
                    value: hexWithOpacity("colorBrandBackground", 0.2, true),
                    applyCondition: active
                })}
                selected={hover} 
                appearance={active ? "filled" : "subtle" } 
                onMouseEnter={() => setHover(true)} 
                onMouseLeave={() => setHover(false)} 
                onMouseOver={() => setHover(true)}
            >
                <Flex row gap={2} fullWidth align="center" justify="start" className="clickable">
                    <div >
                        {
                            React.cloneElement(to.icon, { fontSize: 24, primaryFill: iconColor, className: "sidebar-link-icon" })
                        }
                    </div>
                    <Text weight="bold" style={{color: linkColor}} className="sidebar-link-label">{to.label}</Text>
                </Flex>
            </Card>
        </Tooltip>
    )
}
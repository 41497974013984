import React from "react";
import { useEntityTypes } from "../../../state/entities/useEntityTypes";
import Flex from "../container/Flex";
import { Spinner, Table, TableBody, TableCell, TableHeader, TableRow } from "@fluentui/react-components";
import { IEntityType, IEntityTypeBase } from "../../../types/entityType.schema";
import ModalForm from "../modal/ModalForm";
import useApi from "../../../api/useApi";
import { AddRegular, EditRegular } from "@fluentui/react-icons";
import FormikField from "../formik/FormikField";

export interface IUpdateEntityTypeFormProps {
    entityType?: IEntityType
}

export default function UpdateEntityTypeForm(props: IUpdateEntityTypeFormProps) {

    const {
        entityType
    } = props;

    const {
        entityTypesCreate,
        entityTypesUpdate
    } = useApi();

    const {
        reloadEntityTypes
    } = useEntityTypes();

    return (
        <ModalForm
            title={entityType ? "Typ bearbeiten" : "Typ erstellen"}
            icon={entityType ? <EditRegular /> : <AddRegular />}
            text={entityType ? "Bearbeiten" : "Neuer Firmentyp"}
            initialValues={{
                name: entityType?.name || ""
            } as IEntityTypeBase}
            onSubmit={async (values) => {
                const res = (
                    entityType
                    ? await entityTypesUpdate({
                        ...entityType,
                        ...values
                    })
                    : await entityTypesCreate(values)
                );

                if (!res || !res.success) return false;

                await reloadEntityTypes();

                return true;
            }}
        >
            {
                formik => (
                    <Flex fullWidth>
                        <FormikField
                            className="w-100"
                            name="name"
                            label="Name"
                        />
                    </Flex>
                )
            }
        </ModalForm>
    )
}
import { useFormikContext } from "formik";
import { ChartType, IChartDocument, IChartMapping, IChartMappingGroupBase } from "../../../types/charts.schema";
import { BalanceIndicator, IMapping } from "../../../types/mapping.schema";
import { IMappingGroupWithMappings } from "../../../types/mappingGroup.schema";
import useChartUtil from "../../../hooks/useChartUtil";
import React from "react";
import BalanceTypeBadge from "../mappings/BalanceTypeBadge";
import Flex from "../container/Flex";
import { Button, Card, Checkbox, Text } from "@fluentui/react-components";
import { ArrowClockwiseRegular, ChevronDown48Regular, ChevronUp48Regular, DeleteRegular, EditRegular } from "@fluentui/react-icons";
import CustomButton from "../button/CustomButton";
import ModalDialog from "../modal/ModalDialog";
import MappingList from "../mappings/MappingList";
import FormikField from "../formik/FormikField";
import CheckBox from "../formik/CheckBox";
import { getId } from "../../../util/mongoUtil";
import FormikSelect from "../formik/FormikSelect";
import EntityTypeSelect from "../entityTypes/EntityTypeSelect";
import { parseAppColor } from "../../../util/color";

export interface IChartGroupFieldMappingProps {
    fieldNamePrefix: string,
    isAdjusted: boolean,
    mapping: IMapping,
    chartMapping: IChartMapping,
    mappingId: string,
    mappingGroup: IMappingGroupWithMappings,
    chartGroup: IChartMappingGroupBase,
    swap: (a: number, b: number) => void,
    remove: (i: number) => void,
    index: number,
    getAvailableSankeyMappings: (soruceMappingId: string) => Array<IMapping>
}

export default function ChartGroupFieldMapping(props: IChartGroupFieldMappingProps) {

    const [hover, setHover] = React.useState<boolean>(false);

    const {
        canTypeHaveSecondaryType,
        isTypeAllowedForYoyChart
    } = useChartUtil();

    const {
        setFieldValue,
        values
    } = useFormikContext<IChartDocument>();

    const {
        fieldNamePrefix,
        isAdjusted,
        mapping,
        mappingId,
        chartGroup,
        chartMapping,
        getAvailableSankeyMappings,
        mappingGroup,
        index,
        swap,
        remove
    } = props;

    const getExpectedBalanceTypeForPositiveAdditionBadge = (b: BalanceIndicator) => (
        <BalanceTypeBadge
            onClick={() => setFieldValue(`${fieldNamePrefix}.mappings.${index}.yoyPositiveResultIs`, b)}
            type={b}
            activeType={chartMapping.yoyPositiveResultIs}
        />
    )

    console.log(mapping);

    return (
        <Flex row fullWidth>
            <Flex gap={1}>
                <Button
                    appearance="transparent"
                    icon={<ChevronUp48Regular />}
                    onClick={() => swap(index, index - 1)}
                    disabled={index === 0}
                />
                <Button
                    appearance="transparent"
                    icon={<ChevronDown48Regular />}
                    onClick={() => swap(index, index + 1)}
                    disabled={index === values.groups.length - 1}
                />
            </Flex>
            <Card 
                className="w-100" 
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
            >
                <Flex fullWidth>
                    <Flex fullWidth gap={1}>
                        <Flex row justify="between" fullWidth align="start">
                            <Flex row gap={1}>
                                <Flex gap={0}>
                                    <Text>{mapping.name}</Text>
                                    {
                                        isAdjusted && <Text size={200}>Angezeigt als: {chartMapping.adjustments.name}</Text>
                                    }
                                    {
                                        mapping.isTemplate && <Flex row><Text size={200}>Wird angezeigt für: </Text><EntityTypeSelect size="small" readOnly selectedEntityTypes={mapping.appliesToEntities} /></Flex>
                                    }
                                </Flex>
                                {
                                    !isAdjusted && (
                                        <CustomButton
                                            appearance="transparent"
                                            icon={<EditRegular />}
                                            onClick={() => setFieldValue(
                                                `${fieldNamePrefix}.mappings.${index}.adjustments`,
                                                { name: mapping.name }
                                            )}
                                            text="Name ändern"
                                        />
                                    )
                                }
                                {
                                    (!isAdjusted && hover) && (
                                        <ModalDialog
                                            title="Kontengruppe auswählen"
                                            appearance="outline"
                                            text="Auswählen"
                                        >
                                            <MappingList 
                                                mappingGroup={mappingGroup} 
                                                onSelect={m => setFieldValue(`${fieldNamePrefix}.mappings.${index}.mapping`, m._id)}
                                                selectedKeys={[ mappingId ]}
                                                canSelect
                                            />
                                        </ModalDialog>
                                    )
                                }
                            </Flex>
                            <Button
                                style={parseAppColor("danger").getStyle(true, true)}
                                icon={<DeleteRegular />}
                                appearance="transparent"
                                onClick={() => remove(index)} 
                            />
                        </Flex>
                        {
                            isAdjusted && (
                                <Flex row fullWidth>
                                    <FormikField 
                                        className="w-100"
                                        name={`${fieldNamePrefix}.mappings.${index}.adjustments.name`} 
                                    />
                                    <CustomButton
                                        appearance="transparent"
                                        icon={<ArrowClockwiseRegular />}
                                        onClick={() => setFieldValue(
                                            `${fieldNamePrefix}.mappings.${index}.adjustments`,
                                            undefined
                                        )}
                                        text="Zurücksetzen"
                                    />
                                </Flex>
                            )
                        }
                    </Flex>
                    {
                        (canTypeHaveSecondaryType(values.type) && !!values.secondaryType && (chartMapping.useSecondaryType || hover)) && (
                            <CheckBox   
                                name={`${fieldNamePrefix}.mappings.${index}.useSecondaryType`} 
                                label="Auf sekundärer Achse darstellen" 
                            />
                        )
                    }
                    {
                        (values.isYoY && isTypeAllowedForYoyChart(values.type) && ((!chartGroup.yoyStart && hover) || getId(chartGroup.yoyStart) === mappingId)) && (
                            <Checkbox 
                                checked={getId(chartGroup.yoyStart) === mappingId}
                                label="Start- und Endpunkt für Jahresentwicklung"
                                onChange={(_, e) => setFieldValue(`${fieldNamePrefix}.yoyStart`, e.checked ? mappingId : undefined)}
                            />
                        )
                    }
                    {
                        (values.isYoY && isTypeAllowedForYoyChart(values.type ) && getId(chartGroup.yoyStart) !== mappingId) && (
                            <Flex row gap={2}>
                                <Text>Addition bei Wert im</Text>
                                <Flex row gap={1}>
                                    {getExpectedBalanceTypeForPositiveAdditionBadge(BalanceIndicator.Debit)}
                                    {getExpectedBalanceTypeForPositiveAdditionBadge(BalanceIndicator.Credit)}
                                </Flex>
                            </Flex>
                        )
                    }
                    {
                        values.type === ChartType.Sankey && (
                            <FormikSelect
                                label="Zahlenquelle für Mengenfluss"
                                description="Die Quellkontengruppe, aus welcher der Saldo dieser Gruppe stammt"
                                name={`${fieldNamePrefix}.mappings.${index}.sankeyData.sourceMapping`}
                                options={getAvailableSankeyMappings(mappingId)}
                                valueKey="_id"
                                bold={false}
                                emptyPlaceholder="Bitte hinterlegen Sie mehr als eine Gruppe"
                                allowEmptyValue
                                labelKey="name"
                            />
                        )
                    }
                </Flex>
            </Card>
        </Flex>
    )
}